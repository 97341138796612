import * as React from 'react';

import styles from './ReplacedLocationsAlert.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import InfoIcon from 'common/icons/InfoIcon';

type PropsT = {
    onClose: () => void;
};

const cx = classNames.bind(styles);

const ReplacedLocationsAlert: React.FC<PropsT> = React.memo((props) => {
    const { onClose } = props;

    const { t } = useTranslation();

    return (
        <Alert
            className={cx('wrap')}
            icon={<InfoIcon className={cx('icon')} fillColor={StyleGuideColorsEnum.white} />}
            size={AlertSizeEnum.medium}
            theme={AlertThemeEnum.orange}
            onClose={onClose}
        >
            {t('common:new-order-form.warnings-alert.invalid-route')}
        </Alert>
    );
});

export default ReplacedLocationsAlert;
