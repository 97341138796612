import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import { selectTrucksDict, selectTrucksDictById } from 'common/store/trucks-dict/selectors';

const useTruckDict = () => {
    const dispatch = useDispatch();

    const truckDict = useSelector(selectTrucksDict);
    const truckDictById = useSelector(selectTrucksDictById);

    React.useEffect(() => {
        if (!truckDict) {
            dispatch(fetchTrucksDict());
        }
    }, []);

    return {
        truckDictById,
    };
};

export { useTruckDict };
