import { FieldsEnum, FormValuesT, RoutePointT, ShipmentFieldsEnum } from '../constants';
import { NotUsedRoutePointValidationResultT } from '../NotUsedRoutePointValidationAlert/NotUsedRoutePointValidationAlert';

type MetaT = {
    routePoints: Array<RoutePointT>;
};

export const validateNotUsedRoutePoint = (
    formValues: FormValuesT,
    meta: MetaT,
): NotUsedRoutePointValidationResultT | null => {
    const { routePoints } = meta;

    const allRoutePointIdsSet = new Set(
        routePoints.filter((routePoint) => !routePoint.driveThru).map((routePoint) => routePoint.id),
    );

    formValues[FieldsEnum.shipments]?.forEach((shipmentFormValues) => {
        const pickUpPointId = shipmentFormValues[ShipmentFieldsEnum.pickUpPointId];
        if (pickUpPointId && allRoutePointIdsSet.has(pickUpPointId)) {
            allRoutePointIdsSet.delete(pickUpPointId);
        }

        const dropOffPointId = shipmentFormValues[ShipmentFieldsEnum.dropOffPointId];
        if (dropOffPointId && allRoutePointIdsSet.has(dropOffPointId)) {
            allRoutePointIdsSet.delete(dropOffPointId);
        }
    });

    if (allRoutePointIdsSet?.size) {
        return {};
    }

    return null;
};
