import React from 'react';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './TemperatureRangePicker.scss';

import { TemperatureRangeT } from '../constants';
import RangeSelector from 'common/components/RangeSelector/RangeSelector';
import { StyleGuideColorsEnum, TemperatureModeEnum, temperatureRanges } from 'common/constants';
import { TEMPERATURE_MODE_T } from 'common/components/TemperatureRange/TemperatureRange';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';

const cx = classNames.bind(styles);

type PropsT = {
    name: string;
    label: string;
    value: TemperatureRangeT;
    onChange: (value: TemperatureRangeT) => void;
    className?: string;
};

const MIN_VALUE = -25;
const MAX_VALUE = 25;

type TemperaturePresetT = {
    title: string;
    value: TemperatureRangeT;
    color: string;
};

const formatValue = (timeWindow: TemperatureRangeT): string => {
    if (!timeWindow) {
        return '';
    }

    const [min, max] = timeWindow;

    return `${min} .. ${max} °C`;
};

const temperatureModeColorsMap: Record<TemperatureModeEnum, string> = {
    [TemperatureModeEnum.deepFrozen]: '#1665d8',
    [TemperatureModeEnum.ultraFresh]: '#0e9aae',
    [TemperatureModeEnum.dry]: '#6b6c6f',
    [TemperatureModeEnum.chilled]: '#4b6518',
    [TemperatureModeEnum.ambient]: '#ac7213',
};

const TemperatureRangePicker: React.FC<PropsT> = React.memo((props) => {
    const { name, label, value, onChange, className } = props;

    const { t } = useTranslation();

    const presets: Array<TemperaturePresetT> = React.useMemo(() => {
        return [
            TemperatureModeEnum.deepFrozen,
            TemperatureModeEnum.ultraFresh,
            TemperatureModeEnum.dry,
            TemperatureModeEnum.chilled,
            TemperatureModeEnum.ambient,
        ].map((temperatureMode) => {
            const range = temperatureRanges[temperatureMode] || [];

            return {
                title: t(TEMPERATURE_MODE_T[temperatureMode], {
                    minTemperature: range[0],
                    maxTemperature: range[1],
                }),
                value: range,
                color: temperatureModeColorsMap[temperatureMode],
            };
        });
    }, [t]);

    const handleChange = React.useCallback(
        (range: TemperatureRangeT): void => {
            onChange(range);
        },
        [onChange],
    );

    const labelsConfig = React.useMemo(() => {
        return {
            hasStartStep: true,
            hasEndStep: true,
            shownLabelCount: 25,
            shownStepCount: 5,
        };
    }, []);

    const valuesRange = React.useMemo(() => {
        return [MIN_VALUE, MAX_VALUE];
    }, []);

    const ranges = React.useMemo(() => {
        return [];
    }, []);

    const renderLabel = React.useCallback((value: number): string => {
        return `${value} °C`;
    }, []);

    return (
        <div className={cs(cx('picker'), className)}>
            <div className={cx('label')}>
                <div className={cx('label__icon')}>
                    <SnowflakeIcon fillColor={StyleGuideColorsEnum.brandDark} />
                </div>
                <div className={cx('label__label')}>{label}</div>
                <div className={cx('label__value')}>
                    <PillLabel isSymmetrical theme={PillLabelThemeEnum.charcoal}>
                        {formatValue(value)}
                    </PillLabel>
                </div>
            </div>
            <RangeSelector
                valuesRange={valuesRange}
                availableValues={valuesRange}
                ranges={ranges}
                values={value}
                valueStep={1}
                labelsConfig={labelsConfig}
                renderLabel={renderLabel}
                onSelect={handleChange}
            />
            <div className={cx('presets')}>
                {presets.map((preset) => (
                    <div
                        key={preset.title}
                        className={cx('preset')}
                        onClick={() => handleChange(preset.value)}
                        style={{ color: preset.color }}
                    >
                        {preset.title}
                    </div>
                ))}
            </div>
        </div>
    );
});

export default TemperatureRangePicker;
