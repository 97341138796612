import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './CollapsedOfferDetails.scss';

import { Trans, useTranslation } from 'react-i18next';
import { PriceOfferT } from 'common/store/order-creation/models';
import Money from 'common/components/Money/Money';
import { formatTimeInterval } from 'common/utils/time';
import ExpandIcon from 'common/icons/ExpandIcon';
import { CurrencyEnum, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { moneyOperations } from 'common/utils/money';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import noop from 'lodash/noop';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import BigRadioIcon from 'common/icons/BigRadioIcon';
import BigCheckIcon from 'common/icons/BigCheckIcon';

const cx = classNames.bind(styles);

type PropsT = {
    offer: PriceOfferT;
    diff?: {
        co2: number;
    } | null;
    onSelect: () => void;
    onOpenDetails: () => void;
    isSelectable?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
    isOpenDetails?: boolean;
    additionalServicesPrice: number;
};

const CollapsedOfferDetails: React.FC<PropsT> = React.memo((props) => {
    const {
        onSelect,
        onOpenDetails,
        isSelectable,
        isSelected,
        isDisabled,
        isOpenDetails,
        offer,
        diff,
        additionalServicesPrice,
    } = props;

    const { t } = useTranslation();

    const diffCO2TooltipLabel = diff?.co2
        ? t('common:new-order-details.co2-diff', {
              co2: diff.co2,
          })
        : null;

    const teamDriveTooltipLabel = t('common:new-order-details.team-drive');
    const urgentShipmentTooltipLabel = t('common:new-order-details.urgent-shipment');

    const layoverHoursLabel = `${offer.layoverHours} ${t('common:hours', {
        postProcess: 'interval',
        count: offer.layoverHours,
    })}`;

    const layoverTooltipLabel = offer?.layoverHours ? (
        <Trans
            i18nKey="common:new-order-details.layover"
            components={{
                hours: layoverHoursLabel,
            }}
        />
    ) : null;

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event?.target instanceof HTMLElement) {
            const isPriceDetailsClick = event?.target?.dataset?.clickDetect;
            if (isPriceDetailsClick && isSelectable) {
                return;
            }

            if (isSelectable && !isSelected && !isDisabled) {
                onSelect();
            } else {
                onOpenDetails();
            }
        }
    };

    const handleTriggerClick = () => {
        onOpenDetails();
    };

    const totalOfferAmount = moneyOperations.plusWithFormat(offer.price, additionalServicesPrice, CurrencyEnum.EUR);

    const ratePerKm = totalOfferAmount / offer.distanceKm;

    return (
        <div
            className={cx('details', {
                'details--isSelectable': isSelectable,
            })}
            onClick={handleClick}
            data-test-selector="collapsed-price-offer"
        >
            {isSelectable ? (
                <BigRadioIcon className={cx('icon')} isSelected={isSelected} isDisabled={isDisabled} />
            ) : (
                <BigCheckIcon className={cx('icon')} isDisabled={isDisabled} />
            )}
            <div className={cx('price')}>
                {offer?.price && (
                    <div className={cx('price__total')}>
                        <Money amount={totalOfferAmount} currency={CurrencyEnum.EUR} />
                    </div>
                )}
                <div className={cx('price__description')}>
                    <UnitTypeCount
                        types={[UnitTypeEnum.euroAbbreviation, UnitTypeEnum.kilometersAbbreviation]}
                        count={ratePerKm}
                    />
                </div>
            </div>
            <div className={cx('price-details-trigger')}>
                <Tooltip
                    position={TooltipPositionEnum.topCenter}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                            {t(isOpenDetails ? 'common:tooltips.collapse' : 'common:tooltips.expand')}
                        </TooltipContent>
                    }
                >
                    {() => (
                        <TransparentTrigger
                            spaces="xs"
                            onClick={handleTriggerClick}
                            leftIcon={
                                <ExpandIcon fillColor={StyleGuideColorsEnum.brandAccent} isInvert={isOpenDetails} />
                            }
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    )}
                </Tooltip>
            </div>
            <div className={cx('attributes')}>
                <div className={cx('attributes__inner')}>
                    {offer?.isUrgentShipment && (
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                urgentShipmentTooltipLabel ? (
                                    <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                        {urgentShipmentTooltipLabel}
                                    </TooltipContent>
                                ) : null
                            }
                        >
                            {() => (
                                <TransparentTrigger
                                    spaces="xs"
                                    isDisabled={!urgentShipmentTooltipLabel}
                                    onClick={noop}
                                    leftIcon={
                                        <div
                                            className={cx('attributes__attribute', 'attributes__attribute--isUrgent')}
                                        />
                                    }
                                    reflectionTheme={ReflectionThemeEnum.light}
                                />
                            )}
                        </Tooltip>
                    )}
                    {offer?.hasLayover && (
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                layoverTooltipLabel ? (
                                    <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                        {layoverTooltipLabel}
                                    </TooltipContent>
                                ) : null
                            }
                        >
                            {() => (
                                <TransparentTrigger
                                    spaces="xs"
                                    isDisabled={!layoverTooltipLabel}
                                    onClick={noop}
                                    leftIcon={
                                        <div
                                            className={cx('attributes__attribute', 'attributes__attribute--isLayover')}
                                        />
                                    }
                                    reflectionTheme={ReflectionThemeEnum.light}
                                />
                            )}
                        </Tooltip>
                    )}
                    {offer?.isTeamDrive && (
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                teamDriveTooltipLabel ? (
                                    <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                        {teamDriveTooltipLabel}
                                    </TooltipContent>
                                ) : null
                            }
                        >
                            {() => (
                                <TransparentTrigger
                                    spaces="xs"
                                    isDisabled={!teamDriveTooltipLabel}
                                    onClick={noop}
                                    leftIcon={
                                        <div
                                            className={cx(
                                                'attributes__attribute',
                                                'attributes__attribute--isTeamDrive',
                                            )}
                                        />
                                    }
                                    reflectionTheme={ReflectionThemeEnum.light}
                                />
                            )}
                        </Tooltip>
                    )}
                    {offer?.isGreenTruck && (
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                diffCO2TooltipLabel ? (
                                    <TooltipContent theme={TooltipContentThemeEnum.black} width={180}>
                                        {diffCO2TooltipLabel}
                                    </TooltipContent>
                                ) : null
                            }
                        >
                            {() => (
                                <TransparentTrigger
                                    spaces="xs"
                                    onClick={noop}
                                    isDisabled={!diffCO2TooltipLabel}
                                    leftIcon={
                                        <div
                                            className={cx(
                                                'attributes__attribute',
                                                'attributes__attribute--isGreenTruck',
                                            )}
                                        />
                                    }
                                    reflectionTheme={ReflectionThemeEnum.light}
                                />
                            )}
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={cx('intervals')}>
                <div className={cx('intervals__interval')}>
                    {formatTimeInterval(offer.pickupDockingHoursFrom, offer.pickupDockingHoursTo)}
                </div>
                <div className={cx('intervals__interval')}>
                    {formatTimeInterval(offer.dropOffDockingHoursFrom, offer.dropOffDockingHoursTo)}
                </div>
            </div>
        </div>
    );
});

export default CollapsedOfferDetails;
