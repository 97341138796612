import * as React from 'react';

import styles from './ValidationAlert.scss';
import classNames from 'classnames/bind';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import InfoIcon from 'common/icons/InfoIcon';

type PropsT = {
    onClose: () => void;
};

const cx = classNames.bind(styles);

const ValidationAlert: React.FC<PropsT> = React.memo((props) => {
    const { children, onClose } = props;

    return (
        <Alert
            className={cx('wrap')}
            icon={<InfoIcon className={cx('icon')} fillColor={StyleGuideColorsEnum.white} />}
            size={AlertSizeEnum.medium}
            theme={AlertThemeEnum.tomatoRed}
            onClose={onClose}
        >
            {children}
        </Alert>
    );
});

export default ValidationAlert;
