import * as React from 'react';

import styles from './QuickTimeWindowLink.scss';
import classNames from 'classnames/bind';
import find from 'lodash/find';
import { RangeIdSetEnum } from '../constants';
import { useTranslation } from 'react-i18next';
import { ReservePreviewT } from 'common/store/order-creation/models';
import isNumber from 'lodash/isNumber';
import { RangeSelectorRangeT } from 'common/components/RangeSelector/RangeSelector';
import LinkButton, { LinkButtonHeightEnum, LinkButtonThemeEnum } from 'common/components/LinkButton/LinkButton';

type PropsT = {
    values: TimeWindowT | null;
    routePointIndex: number;
    ranges: RangeSelectorRangeT[];
    onClick: (date: string | null | undefined) => void;
    reservePreview: ReservePreviewT | null;
    isDisabled?: boolean;
};

const cx = classNames.bind(styles);

const INVERSE_MAP: Record<RangeIdSetEnum, RangeIdSetEnum | null> = {
    [RangeIdSetEnum.urgentPickUp]: RangeIdSetEnum.regularPickUp,
    [RangeIdSetEnum.regularPickUp]: RangeIdSetEnum.urgentPickUp,
    [RangeIdSetEnum.teamDriveDropOff]: RangeIdSetEnum.regularDropOff,
    [RangeIdSetEnum.layoverDropOff]: RangeIdSetEnum.regularDropOff,
    [RangeIdSetEnum.regularDropOff]: RangeIdSetEnum.teamDriveDropOff,
    [RangeIdSetEnum.broker]: null,
};

const checkHasInverseRange = (
    reservePreview: ReservePreviewT | null,
    routePointIndex: number,
    inverseRangeId: string | null | undefined,
): boolean => {
    if (!inverseRangeId) {
        return false;
    }

    if (inverseRangeId === RangeIdSetEnum.urgentPickUp) {
        return !!reservePreview?.timeWindows?.[routePointIndex]?.urgentTimeWindow?.start;
    }

    if (inverseRangeId === RangeIdSetEnum.regularPickUp) {
        return !!reservePreview?.timeWindows?.[routePointIndex]?.regularTimeWindow?.start;
    }

    if (inverseRangeId === RangeIdSetEnum.teamDriveDropOff) {
        return !!reservePreview?.timeWindows?.[routePointIndex]?.urgentTimeWindow?.start;
    }

    if (inverseRangeId === RangeIdSetEnum.regularDropOff) {
        return !!reservePreview?.timeWindows?.[routePointIndex]?.regularTimeWindow?.start;
    }

    return false;
};

const QuickTimeWindowLink: React.FC<PropsT> = React.memo((props) => {
    const { values, routePointIndex, ranges, onClick, reservePreview, isDisabled } = props;

    const { t } = useTranslation();

    let inverseRangeId: RangeIdSetEnum | null = null;

    if (values) {
        const currentRange = find(ranges, (range) => {
            const [start, end] = range?.values || [];
            return isNumber(start) && isNumber(end) && start <= values[0] && values[0] < end;
        });

        inverseRangeId = INVERSE_MAP[currentRange?.id as RangeIdSetEnum];
    }

    const hasInverseRange = checkHasInverseRange(reservePreview, routePointIndex, inverseRangeId);

    if (!hasInverseRange) {
        return null;
    }

    const labels = {
        [RangeIdSetEnum.urgentPickUp]: t('common:time-windows-links.urgent-pickup'),
        [RangeIdSetEnum.regularPickUp]: t('common:time-windows-links.regular-pickup'),
        [RangeIdSetEnum.teamDriveDropOff]: t('common:time-windows-links.team-drive'),
        [RangeIdSetEnum.regularDropOff]: t('common:time-windows-links.regular-drive'),
        [RangeIdSetEnum.layoverDropOff]: t('common:time-windows-links.layover-drop-off'),
        [RangeIdSetEnum.broker]: null,
    };

    if (!inverseRangeId) {
        return null;
    }

    const handleClick = () => {
        if (inverseRangeId === RangeIdSetEnum.urgentPickUp || inverseRangeId === RangeIdSetEnum.teamDriveDropOff) {
            onClick(reservePreview?.timeWindows?.[routePointIndex]?.urgentTimeWindow?.start);
        }

        if (inverseRangeId === RangeIdSetEnum.regularPickUp || inverseRangeId === RangeIdSetEnum.regularDropOff) {
            onClick(reservePreview?.timeWindows?.[routePointIndex]?.regularTimeWindow?.start);
        }
    };

    return (
        <LinkButton
            theme={LinkButtonThemeEnum.brandDark}
            height={LinkButtonHeightEnum.narrow}
            onClick={() => handleClick()}
            isDisabled={isDisabled}
        >
            {labels[inverseRangeId]}
        </LinkButton>
    );
});

export default QuickTimeWindowLink;
