import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ClientPage.scss';
import LeftMenuLayout from '../LeftMenuLayout/LeftMenuLayout';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routesEnum } from 'shipper/constants';

import AccountPage from 'common/layouts/AccountPage/AccountPage';
import OrdersPage from '../OrdersPage/OrdersPage';
import TeamPage from 'common/layouts/TeamPage/TeamPage';
import User from 'common/components/User/User';
import Company from 'common/components/Company/Company';
import RedirectPendingUser from 'common/components/RedirectPendingUser/RedirectPendingUser';
import RFQsPage from '../RFQsPage/RFQsPage';
import ProfilePage from '../ProfilePage/ProfilePage';
import InvoicesListPage from '../InvoicesListPage/InvoicesListPage';

const cx = classNames.bind(styles);

type PropsT = {};

const ClientPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <>
            <User />
            <RedirectPendingUser />
            <Company />
            <LeftMenuLayout>
                <div className={cx('content')}>
                    <Switch>
                        <Route exact path={routesEnum.home}>
                            <Redirect to={routesEnum.orders} />
                        </Route>
                        <Route path={routesEnum.orders}>
                            <OrdersPage />
                        </Route>
                        <Route path={routesEnum.rfqs}>
                            <RFQsPage />
                        </Route>
                        <Route path={routesEnum.team}>
                            <TeamPage />
                        </Route>
                        <Route path={routesEnum.invoices}>
                            <InvoicesListPage />
                        </Route>
                        <Route path={routesEnum.profile}>
                            <ProfilePage />
                        </Route>
                        <Route path={routesEnum.account}>
                            <AccountPage />
                        </Route>
                        <Route path="*">
                            <Redirect to={routesEnum.notFound} />
                        </Route>
                    </Switch>
                </div>
            </LeftMenuLayout>
        </>
    );
});

export default ClientPage;
