import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './OrderListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrdersPage } from 'common/store/orders/actions';
import { selectOrdersByIds, selectOrdersPages, selectOrdersTotal } from 'common/store/orders/selectors';
import { OMSRoutesEnum } from 'common/constants';
import OrdersTable from 'shipper/layouts/OrdersPage/OrderListPage/OrdersTable/OrdersTable';
import { OrderT } from 'common/store/orders/models';
import { Trans, useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import history from 'common/utils/history';
import { generatePath } from 'react-router';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import { OrderSortEnum, ShipperOrdersQuickFilterEnum } from 'common/utils/api/models';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import Pagination from 'common/components/Table/Pagination/Pagination';
import { formatDateInterval } from 'common/utils/formatters';
import omit from 'lodash/omit';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import QuickFilters, { QuickFiltersOptionT } from 'common/components/QuickFilters/QuickFilters';
import { isNonNil } from 'common/utils';
import { NotificationActionEnum } from 'common/store/notifications/models';
import { subscribe } from 'common/utils/notification-pub-sub';
import { selectOrdersStats } from 'common/store/orders-stats/selectors';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { selectRelatedIdsSet } from 'common/store/notifications/selectors';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import VerificationAlert from 'common/components/VerificationAlert/VerificationAlert';
import { selectCompanyVerificationProblems, selectCurrentCompany } from 'common/store/company/selectors';
import { urlFactory } from 'shipper/utils/urls';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import { selectGroupedNotificationCounts } from 'common/store/grouped-notification-counts/selectors';
import { fetchGroupedNotificationCounts } from 'common/store/grouped-notification-counts/actions';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';
import EmptyMessage from 'shipper/layouts/OrdersPage/OrderListPage/EmptyMessage/EmptyMessage';
import SideBars from 'shipper/layouts/SideBars/SideBars';
import { useCheckOpenedSidebar, useOpenLeftSidebar } from 'shipper/layouts/SideBars/hooks';
import { ShipperSidebarsTypeEnum } from 'shipper/layouts/SideBars/constants';
import { useQueryParams } from 'use-query-params';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { logWarning } from 'common/utils/logger';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';
import { ordersPaginationChannel, ordersRefreshChannel } from 'common/store/orders/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { InferChannelEventT } from 'common/utils/view-event-channel';

const cx = classNames.bind(styles);

type PropsT = {};

const OrderListPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId, partnerType } = usePartnerContext();

    const { historyPush } = useHistoryPush();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const total = useSelector(selectOrdersTotal);
    const ordersPages = useSelector(selectOrdersPages);
    const orderById = useSelector(selectOrdersByIds);

    const updatedOrderIdsSet = useSelector(selectRelatedIdsSet);

    const unReadNotificationsGroupedCounts = useSelector(selectGroupedNotificationCounts);

    const openLeftSidebar = useOpenLeftSidebar();
    const openOrdersFilter = React.useCallback(() => {
        openLeftSidebar({
            type: ShipperSidebarsTypeEnum.ordersFilter,
        });
    }, [openLeftSidebar]);

    const isOpenedFilters = useCheckOpenedSidebar(ShipperSidebarsTypeEnum.ordersFilter);

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.ordersFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.ordersPage]: createPageNumberParam(),
        [QueryKeysEnum.ordersSort]: createSortParams<OrderSortEnum>({
            value: OrderSortEnum.createdDate,
            direction: SortDirectionEnum.DESC,
        }),
    });

    const pageNumber = query[QueryKeysEnum.ordersPage];
    const selectedSort = query[QueryKeysEnum.ordersSort];
    const queryFilters = query[QueryKeysEnum.ordersFilters];

    const page = ordersPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchOrdersPage(pageNumber, query, { isForceUpdate: false }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchOrdersPage(pageNumber, query, { isForceUpdate: false }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));

        return subscribe([NotificationActionEnum.ORDER_STATUS_CHANGED], () => {
            const query = prepareFetchPageQuery(queryFilters, selectedSort);
            dispatch(fetchOrdersPage(pageNumber, query, { isForceUpdate: true }));

            dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));
        });
    }, [pageNumber, selectedSort, queryFilters]);

    const orders = React.useMemo(() => {
        return (ids || []).filter(isNonNil).map((id): OrderT => orderById[id]);
    }, [ids, orderById]);

    const goToCreateOrderForm = (): void => {
        history.push({
            pathname: OMSRoutesEnum.newOrder,
        });
    };

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchOrdersPage(pageNumber, query, { isForceUpdate: true }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters]);
    useChannelSubscribe(ordersRefreshChannel, refreshPageHandler);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.ordersPage]: pageNumber,
            });
        },
        [changeQuery],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof ordersPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(ordersPaginationChannel, setPageHandler);

    const goToOrderDetails = (event: React.MouseEvent, orderId: OrderIdT) => {
        const pathname = generatePath(OMSRoutesEnum.orderDetails, {
            orderId,
        });

        historyPush(event, pathname + history.location.search);
    };

    const sortOptions: Array<SortDropdownOptionT<OrderSortEnum>> = React.useMemo(
        () => [
            {
                label: t('orders-table.sorts.triggers.createdDate'),
                value: OrderSortEnum.createdDate,
            },
            {
                label: t('orders-table.sorts.triggers.cost'),
                value: OrderSortEnum.cost,
            },
            {
                label: t('orders-table.sorts.triggers.dropOffDateFrom'),
                value: OrderSortEnum.dropOffDateFrom,
            },
            {
                label: t('orders-table.sorts.triggers.pickupDateFrom'),
                value: OrderSortEnum.pickupDateFrom,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<OrderSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.ordersPage]: 0,
            [QueryKeysEnum.ordersSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: ShipperOrdersQuickFilterEnum | undefined) => {
        const newQueryFilters = {
            ...queryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
        };

        changeQuery({
            [QueryKeysEnum.ordersPage]: 0,
            [QueryKeysEnum.ordersFilters]: newQueryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.ordersFilters] || {};

        const queryFilters = {
            [QueryFiltersKeysEnum.quickFilter]: prevQueryFilters[QueryFiltersKeysEnum.quickFilter],
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.ordersPage]: 0,
            [QueryKeysEnum.ordersFilters]: queryFilters,
        });
    };

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.origin', {
                        value: queryFilters[QueryFiltersKeysEnum.origin],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.origin],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.destination', {
                        value: queryFilters[QueryFiltersKeysEnum.destination],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.destination],
            },
            {
                renderIcon: (queryFilters) => {
                    const name = queryFilters[QueryFiltersKeysEnum.createdByName] || '';

                    return pillIconRendererMap.avatar(name, true);
                },
                render: (queryFilters) => {
                    return t('orders-table.filter-labels.created-by', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdById, QueryFiltersKeysEnum.createdByName],
            },
            {
                render: (queryFilters) => {
                    const trailerDictId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="orders-table.filter-labels.trailer-type"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={trailerDictId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('orders-table.filter-labels.pickup', {
                        value: formatDateInterval(pickupDateFrom, pickupDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('orders-table.filter-labels.drop-off', {
                        value: formatDateInterval(dropOffDateFrom, dropOffDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
            {
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('orders-table.filter-labels.created', {
                        value: formatDateInterval(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
        ],
        [],
    );

    const hasSelectedStatus = !!queryFilters[QueryFiltersKeysEnum.quickFilter];

    const onlyFiltersQuery = omit(queryFilters, [QueryFiltersKeysEnum.quickFilter]);
    const hasSelectedFilters = !!Object.keys(onlyFiltersQuery).length;

    const ordersStats = useSelector(selectOrdersStats);

    const currentCompany = useSelector(selectCurrentCompany);
    const isDisabledCreateOrder = !currentCompany;
    const verificationProblems = useSelector(selectCompanyVerificationProblems);

    const goToProfile = (): void => {
        let link;

        if (verificationProblems.anyBasicInformation) {
            link = urlFactory.profileBasic();
        } else if (verificationProblems.anyDocument) {
            link = urlFactory.profileDocuments();
        }

        if (link) {
            history.push(link);
        }
    };

    const goToUserDetails = React.useCallback(
        (userId: UserIdT) => {
            if (!partnerId || !partnerType) {
                logWarning('failed to open contact details in order list, empty partnerId or partnerType');
                return;
            }

            openLeftSidebar({
                type: CommonSidebarsTypeEnum.contact,
                partnerId,
                partnerType,
                userId,
            });
        },
        [partnerId, partnerType],
    );

    const clearAllFilters = () => {
        handleSetQueryFilters({});
    };

    const orderQuickFilterOptions: Array<QuickFiltersOptionT<ShipperOrdersQuickFilterEnum>> = [
        {
            id: undefined,
            testSelector: 'all',
            showPriority: 0,
            hidePriority: 0,
            label: t('orders-table.status-filter.all'),
        },
        {
            id: ShipperOrdersQuickFilterEnum.placed,
            testSelector: ShipperOrdersQuickFilterEnum.placed,
            showPriority: 2,
            hidePriority: 2,
            label: t('orders-table.status-filter.placed'),
            notificationProps: {
                count: ordersStats?.placed,
                theme: colorCodingNotificationLabelTheme.normal,
            },
        },
        {
            id: ShipperOrdersQuickFilterEnum.inProgress,
            testSelector: ShipperOrdersQuickFilterEnum.inProgress,
            showPriority: 3,
            hidePriority: 3,
            label: t('orders-table.status-filter.in-progress'),
            notificationProps: {
                count: ordersStats?.inProgress,
                theme: colorCodingNotificationLabelTheme.normal,
            },
        },
        {
            id: ShipperOrdersQuickFilterEnum.delivered,
            testSelector: ShipperOrdersQuickFilterEnum.delivered,
            showPriority: 4,
            hidePriority: 4,
            label: t('orders-table.status-filter.delivered'),
            notificationProps: {
                count: ordersStats?.delivered,
                theme: colorCodingNotificationLabelTheme.success,
            },
        },
        {
            id: ShipperOrdersQuickFilterEnum.done,
            testSelector: ShipperOrdersQuickFilterEnum.done,
            showPriority: 5,
            hidePriority: 5,
            label: t('orders-table.status-filter.done'),
            notificationProps: {
                isShowPlusSign: true,
                count: unReadNotificationsGroupedCounts?.ORDER_STATUS_CHANGED?.DONE,
                theme: colorCodingNotificationLabelTheme.success,
            },
        },
        {
            id: ShipperOrdersQuickFilterEnum.withIssues,
            testSelector: ShipperOrdersQuickFilterEnum.withIssues,
            showPriority: 6,
            hidePriority: 6,
            label: t('orders-table.status-filter.with-issues'),
            notificationProps: {
                count: ordersStats?.withIssues,
                theme: colorCodingNotificationLabelTheme.warning,
            },
        },
        {
            id: ShipperOrdersQuickFilterEnum.cancelled,
            testSelector: ShipperOrdersQuickFilterEnum.cancelled,
            showPriority: 7,
            hidePriority: 7,
            label: t('orders-table.status-filter.cancelled'),
            notificationProps: {
                isShowPlusSign: true,
                count: unReadNotificationsGroupedCounts?.ORDER_STATUS_CHANGED?.CANCELED,
                theme: colorCodingNotificationLabelTheme.notImportant,
            },
        },
    ];

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.orders')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('orders-table.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderTabsLayout>
                        <QuickFilters
                            options={orderQuickFilterOptions}
                            testSelector="orders"
                            selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                            onSelect={handleSetStatusFilter}
                            moreStatusesLabel={t('orders-table.status-filter.more-statuses')}
                        />
                    </ListPageHeaderTabsLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    testSelector="orders"
                                    className={cx('controls__filters')}
                                    title={t('orders-table.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={openOrdersFilter}
                                />
                                <SortDropdown
                                    testSelector="orders"
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                            </>
                        }
                        rightToolsNode={
                            <>
                                {!verificationProblems.any && (
                                    <Button
                                        theme={ButtonThemeEnum.primary}
                                        type="button"
                                        onClick={goToCreateOrderForm}
                                        isDisabled={isDisabledCreateOrder}
                                        testSelector="create-new-order"
                                    >
                                        {t('orders-table.actions.create-order')}
                                    </Button>
                                )}
                            </>
                        }
                        filterTagsNode={
                            <FilterPills<QueryFiltersT>
                                isCompact
                                testSelector="orders"
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                                config={filterPillsConfig}
                            />
                        }
                    />
                    {verificationProblems.any && (
                        <VerificationAlert
                            className={cx('verification-alert')}
                            actionText={t('inline-alerts.verification-company-problem.action')}
                            message={t('inline-alerts.verification-company-problem.message')}
                            onClickAction={goToProfile}
                        />
                    )}
                    {!orders.length && requestStatus?.ok && (
                        <EmptyMessage
                            hasSelectedStatus={hasSelectedStatus}
                            hasSelectedFilters={hasSelectedFilters}
                            hasVerificationProblem={verificationProblems.any}
                            goToProfile={goToProfile}
                            goToCreateOrderForm={goToCreateOrderForm}
                            clearAllFilters={clearAllFilters}
                        />
                    )}
                    {!orders.length && requestStatus?.error && <TableError />}
                    <OrdersTable
                        orders={orders}
                        goToOrderDetails={goToOrderDetails}
                        isLoading={requestStatus?.loading}
                        updatedOrderIdsSet={updatedOrderIdsSet}
                        goToUserDetails={goToUserDetails}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <SideBars />
        </ScrollableContent>
    );
});

export default OrderListPage;
