import { OverloadingTrailerValidationResultT } from '../OverloadingTrailerValidationAlert/OverloadingTrailerValidationAlert';
import { UnitTypeEnum } from 'common/constants';
import { FormValuesT, RoutePointT, ShipmentFieldsEnum } from 'common/layouts/NewOrderPage/CargoDetailsForm/constants';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import { FieldsEnum } from '../constants';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';
import findIndex from 'lodash/findIndex';

type MetaT = {
    trailerType: ApiTrailerDictTypeT | null;
    routePoints: Array<RoutePointT>;
};

type StatsT = {
    [UnitTypeEnum.eur1Pallets]: number;
    [UnitTypeEnum.eur2Pallets]: number;
    [UnitTypeEnum.kilogramsAbbreviation]: number;
};

export const validateOverloadingTrailer = (
    formValues: FormValuesT,
    meta: MetaT,
): OverloadingTrailerValidationResultT | null => {
    const { routePoints, trailerType } = meta;

    if (!trailerType) {
        return null;
    }

    const statsByPointId = routePoints.reduce<Record<RoutePointIdT, StatsT>>((acc, routePoint) => {
        acc[routePoint.id] = {
            [UnitTypeEnum.eur1Pallets]: 0,
            [UnitTypeEnum.eur2Pallets]: 0,
            [UnitTypeEnum.kilogramsAbbreviation]: 0,
        };

        return acc;
    }, {});

    const shipmentsFormValues = formValues[FieldsEnum.shipments] || [];
    shipmentsFormValues.forEach((shipmentValues) => {
        const pickupPointId = shipmentValues[ShipmentFieldsEnum.pickUpPointId];
        const dropOffPointId = shipmentValues[ShipmentFieldsEnum.dropOffPointId];

        const cargoUnitType = shipmentValues[ShipmentFieldsEnum.cargoUnitType];
        const cargoUnitsCount = parseSimpleNumber(shipmentValues[ShipmentFieldsEnum.cargoUnitsCount]) || 0;
        if (cargoUnitType === UnitTypeEnum.eur1Pallets) {
            if (pickupPointId && statsByPointId[pickupPointId]) {
                statsByPointId[pickupPointId][UnitTypeEnum.eur1Pallets] += cargoUnitsCount;
            }

            if (dropOffPointId && statsByPointId[dropOffPointId]) {
                statsByPointId[dropOffPointId][UnitTypeEnum.eur1Pallets] -= cargoUnitsCount;
            }
        }

        if (cargoUnitType === UnitTypeEnum.eur2Pallets) {
            if (pickupPointId && statsByPointId[pickupPointId]) {
                statsByPointId[pickupPointId][UnitTypeEnum.eur2Pallets] += cargoUnitsCount;
            }

            if (dropOffPointId && statsByPointId[dropOffPointId]) {
                statsByPointId[dropOffPointId][UnitTypeEnum.eur2Pallets] -= cargoUnitsCount;
            }
        }

        const cargoWeight = parseSimpleNumber(shipmentValues[ShipmentFieldsEnum.cargoWeight]) || 0;
        if (pickupPointId && statsByPointId[pickupPointId]) {
            statsByPointId[pickupPointId][UnitTypeEnum.kilogramsAbbreviation] += cargoWeight;
        }

        if (dropOffPointId && statsByPointId[dropOffPointId]) {
            statsByPointId[dropOffPointId][UnitTypeEnum.kilogramsAbbreviation] -= cargoWeight;
        }
    });

    let result: OverloadingTrailerValidationResultT | null = null;
    const summaryStats: StatsT = {
        [UnitTypeEnum.eur1Pallets]: 0,
        [UnitTypeEnum.eur2Pallets]: 0,
        [UnitTypeEnum.kilogramsAbbreviation]: 0,
    };

    routePoints?.forEach((routePoint, index) => {
        if (!statsByPointId[routePoint.id] || result) {
            return;
        }

        summaryStats[UnitTypeEnum.eur1Pallets] += statsByPointId[routePoint.id][UnitTypeEnum.eur1Pallets];
        summaryStats[UnitTypeEnum.eur2Pallets] += statsByPointId[routePoint.id][UnitTypeEnum.eur2Pallets];
        summaryStats[UnitTypeEnum.kilogramsAbbreviation] +=
            statsByPointId[routePoint.id][UnitTypeEnum.kilogramsAbbreviation];

        if (isNumber(trailerType.eur1Pallets) && summaryStats[UnitTypeEnum.eur1Pallets] > trailerType.eur1Pallets) {
            result = {
                unitType: UnitTypeEnum.eur1Pallets,
                pointAIndex: index,
                pointBIndex: findIndex(routePoints, (routePoint) => !routePoint.driveThru, index + 1),
            };
        }

        if (isNumber(trailerType.eur2Pallets) && summaryStats[UnitTypeEnum.eur2Pallets] > trailerType.eur2Pallets) {
            result = {
                unitType: UnitTypeEnum.eur2Pallets,
                pointAIndex: index,
                pointBIndex: findIndex(routePoints, (routePoint) => !routePoint.driveThru, index + 1),
            };
        }

        if (isNumber(trailerType.weight) && summaryStats[UnitTypeEnum.kilogramsAbbreviation] > trailerType.weight) {
            result = {
                unitType: UnitTypeEnum.kilogramsAbbreviation,
                pointAIndex: index,
                pointBIndex: findIndex(routePoints, (routePoint) => !routePoint.driveThru, index + 1),
            };
        }
    });

    return result;
};
