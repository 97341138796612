import * as React from 'react';
import { UnitTypeEnum } from 'common/constants';
import ValidationAlert from '../ValidationAlert/ValidationAlert';
import { useTranslation } from 'react-i18next';

export type OverloadingTrailerValidationResultT = {
    unitType: UnitTypeEnum;
    pointAIndex: number;
    pointBIndex: number;
};

type PropsT = {
    validationResult: OverloadingTrailerValidationResultT | null;
    onClose: () => void;
};

const OverloadingTrailerValidationAlert: React.FC<PropsT> = React.memo((props) => {
    const { validationResult, onClose } = props;

    const { t } = useTranslation();

    if (!validationResult) {
        return null;
    }

    const { pointAIndex, pointBIndex } = validationResult;

    let messageNode: React.ReactNode = null;

    if (
        validationResult?.unitType === UnitTypeEnum.eur1Pallets ||
        validationResult?.unitType === UnitTypeEnum.eur2Pallets
    ) {
        messageNode = t('common:new-order-details.validate-messages.overloading-units', {
            pointA: pointAIndex + 1,
            pointB: pointBIndex + 1,
        });
    }

    if (validationResult?.unitType === UnitTypeEnum.kilogramsAbbreviation) {
        messageNode = t('common:new-order-details.validate-messages.overloading-weight', {
            pointA: pointAIndex + 1,
            pointB: pointBIndex + 1,
        });
    }

    return <ValidationAlert onClose={onClose}>{messageNode}</ValidationAlert>;
});

export default OverloadingTrailerValidationAlert;
