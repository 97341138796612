import React from 'react';

import { useFormikContext } from 'formik';
import FormikField, {
    FormikFieldChildrenPropsT,
    FormikFieldPropsT,
} from 'common/components/forms/FormikField/FormikField';
import get from 'lodash/get';

export type PropsT<TFieldValues extends FieldValues, TFieldPath extends FieldPath<TFieldValues>> = Omit<
    FormikFieldPropsT<FieldPathValue<TFieldValues, TFieldPath>>,
    'children' | 'name' | 'setFieldValue' | 'setFieldTouched' | 'meta'
> & {
    name: TFieldPath;
    children: (
        props: FormikFieldChildrenPropsT<FieldPathValue<TFieldValues, TFieldPath>>,
        extendsProps: {
            name: TFieldPath;
            value: FieldPathValue<TFieldValues, TFieldPath>;
        },
    ) => React.ReactElement | null;
};

const FormikConnectContextField = <TFieldValues extends FieldValues, TFieldPath extends FieldPath<TFieldValues>>(
    props: PropsT<TFieldValues, TFieldPath>,
) => {
    const { name, children, ...restProps } = props;

    const formik = useFormikContext<TFieldValues>();

    const value = get(formik.values, name);
    const error = get(formik.errors, name);

    const extendsProps = React.useMemo(() => {
        return {
            name,
            value,
        };
    }, [name, value]);

    return (
        <FormikField<FieldPathValue<TFieldValues, TFieldPath>>
            name={name}
            error={error}
            meta={formik.getFieldMeta(name)}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            {...restProps}
        >
            {(props) => children(props, extendsProps)}
        </FormikField>
    );
};

export default React.memo(FormikConnectContextField) as typeof FormikConnectContextField;
