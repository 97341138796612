import { useMemo } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { isNonNil } from 'common/utils';

export const useCheckHasMoreQueryFilters = (sourceQueryFilters: Record<string, any>, queryKeys: Array<string>) => {
    return useMemo(() => {
        const queryFilters = pickBy(sourceQueryFilters, isNonNil);
        if (isEmpty(queryFilters)) {
            return false;
        }

        const restQueryFilters = omit(queryFilters, queryKeys);

        return !isEmpty(restQueryFilters);
    }, [sourceQueryFilters, queryKeys]);
};
