import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { QueryFiltersT } from 'common/models';
import { QueryFiltersKeysEnum } from 'common/constants';
import { Trans, useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { formatDateInterval } from 'common/utils/formatters';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';
import { RFQSourceEnum } from 'common/utils/api/models';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const sourceMapT: Record<RFQSourceEnum, string> = {
    [RFQSourceEnum.internal]: 'common:rfqs-page.table.filters.source-type.internal',
    [RFQSourceEnum.imported]: 'common:rfqs-page.table.filters.source-type.imported',
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:rfqs-page.table.filters.pills.search', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const trailerDictId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="common:rfqs-page.table.filters.pills.trailer-type"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={trailerDictId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:rfqs-page.table.filters.pills.address', {
                        value: queryFilters[QueryFiltersKeysEnum.address],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.address],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('common:rfqs-page.table.filters.pills.pickup', {
                        value: formatDateInterval(pickupDateFrom, pickupDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('common:rfqs-page.table.filters.pills.drop-off', {
                        value: formatDateInterval(dropOffDateFrom, dropOffDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('common:rfqs-page.table.filters.pills.created-dates', {
                        value: formatDateInterval(createdDateFrom, createdDateTo, 'YYYY-MM-DD'),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                renderIcon: (queryFilters, isCompact) => {
                    return pillIconRendererMap.avatar(queryFilters[QueryFiltersKeysEnum.createdByName], isCompact);
                },
                render: (queryFilters) => {
                    return t('common:rfqs-page.table.filters.pills.reporter', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdById, QueryFiltersKeysEnum.createdByName],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const source = queryFilters[QueryFiltersKeysEnum.source];
                    return t('common:rfqs-page.table.filters.pills.source', {
                        value: t(sourceMapT[source as RFQSourceEnum]),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.source],
            },
        ],
        [t],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;
