import * as React from 'react';
import ValidationAlert from '../ValidationAlert/ValidationAlert';
import { useTranslation } from 'react-i18next';

export type NotUsedRoutePointValidationResultT = {};

type PropsT = {
    validationResult: NotUsedRoutePointValidationResultT | null;
    onClose: () => void;
};

const NotUsedRoutePointValidationAlert: React.FC<PropsT> = React.memo((props) => {
    const { validationResult, onClose } = props;

    const { t } = useTranslation();

    if (!validationResult) {
        return null;
    }

    return (
        <ValidationAlert onClose={onClose}>
            {t('common:new-order-details.validate-messages.not-used-pickup-delivery-point')}
        </ValidationAlert>
    );
});

export default NotUsedRoutePointValidationAlert;
