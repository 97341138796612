import * as React from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames/bind';
import styles from './ShipmentDetailsForm.scss';
import { useTranslation } from 'react-i18next';
import { FormikContext, useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import {
    DEFAULT_BOX_TRAILER_ID,
    DEFAULT_TILT_TRAILER_ID,
    DEFAULT_TRAILER_TYPE,
    FieldsEnum,
    FormTouchedT,
    FormValuesT,
    RoutePointFieldsEnum,
    RoutePointFormValuesT,
    RoutePointTypeEnum,
    SHIPMENT_DETAIL_FORM_NAME,
} from './constants';
import { StyleGuideColorsEnum, TrailerTypeEnum } from 'common/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCreatePriceOfferWithLaneRequest,
    selectCreateRFQRequest,
    selectIsAllowCreateRFQ,
    selectReserve,
    selectReservePreview,
    selectReservePreviewRequest,
    selectReserveRequest,
} from 'common/store/order-creation/selectors';
import TrailerTypeSwitcher from 'common/components/TrailerTypeSwitcher/TrailerTypeSwitcher';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import validateForm from 'common/layouts/NewOrderPage/ShipmentDetailsForm/validate-form';
import {
    changeReserveQuery,
    createPriceOfferWithLaneRequest,
    createRFQ,
    resetReserveAndPreviewReserve,
} from 'common/store/order-creation/actions';
import {
    prepareApiActualOrderRequest,
    prepareApiReserveChanges,
    prepareApiRoutePointReserveChanges,
} from './prepare-api-requests';
import ValuesStorage from 'common/utils/form-values-storage';
import getterFormFillPercent from '../getter-form-fill-percent';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import ReplacedLocationsAlert from 'common/layouts/NewOrderPage/ShipmentDetailsForm/ReplacedLocationsAlert/ReplacedLocationsAlert';
import FormikField from 'common/components/forms/FormikField/FormikField';
import ShipperDropdown from 'broker-admin/components/dropdowns/ShipperSuggest/ShipperDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { OnChangeRoutePointReserveT } from './models';
import { logWarning } from 'common/utils/logger';
import keys from 'lodash/keys';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { selectShippersById } from 'common/store/shipper-suggest/selectors';
import { MS_IN_MIN, MS_IN_SEC } from 'common/utils/time';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import ButtonTimer from 'common/components/ButtonTimer/ButtonTimer';
import { useSyncReserveChannels } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/use-sync-reserve-channels';
import { useReserveChannelLock } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/use-reserve-channel-lock';
import { useOnlyAvailableLane } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/use-only-available-lane';
import StepsContext from 'common/layouts/NewOrderPage/contexts/steps-context';
import OrderCreationPercentContext from 'common/layouts/NewOrderPage/contexts/order-creation-percent-context';
import { getRoutePointFieldName } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/utils';
import times from 'lodash/times';
import { SyncShipmentDetailsFormValuesContext } from './contexts/sync-form-values';
import { useTrailersDict } from 'common/utils/hooks/useTrailersDict';
import { useCountriesByCode } from 'common/utils/hooks/useCountriesByCode';
import FormikFieldArray from 'common/components/forms/FormikFieldArray/FormikFieldArray';
import PickupOrDeliveryRoutePointPicker from './PickupOrDeliveryRoutePointPicker/PickupOrDeliveryRoutePointPicker';
import DriveThroughRoutePointPicker from './DriveThroughRoutePointPicker/DriveThroughRoutePointPicker';
import { DividerWithDropdownControl } from 'design-system/components/DividerWithDropdownControl/DividerWithDropdownControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CircleIcon from 'common/icons/CircleIcon';
import { SyncShipmentDetailsFormMapStateContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-map-state';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import getBoundingBox from 'common/utils/get-bounding-box';
import LaneDropdownInput from './LaneDropdownInput/LaneDropdownInput';
import { useSelectedLane } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/use-selected-lane';
import { useEditRouteWatcher } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/use-edit-route-watcher';
import SubmitButtonContent from 'common/layouts/NewOrderPage/ShipmentDetailsForm/SubmitButtonContent/SubmitButtonContent';

const cx = classNames.bind(styles);

type PropsT = {
    isBroker: boolean;
};

const storage = new ValuesStorage(SHIPMENT_DETAIL_FORM_NAME);

const DEFAULT_TRAILER_DICT_ID: Record<TrailerTypeEnum, TrailerDictIdT | null> = {
    [TrailerTypeEnum.box]: DEFAULT_BOX_TRAILER_ID,
    [TrailerTypeEnum.tilt]: DEFAULT_TILT_TRAILER_ID,
    [TrailerTypeEnum.reef]: null,
};

export const DEFAULT_ROUTE_POINT_INITIAL_VALUES: RoutePointFormValuesT = {
    [RoutePointFieldsEnum.fakeId]: uuidv4(),
    [RoutePointFieldsEnum.id]: null,
    [RoutePointFieldsEnum.type]: RoutePointTypeEnum.pickupOrDelivery,
    [RoutePointFieldsEnum.location]: null,
    [RoutePointFieldsEnum.timeWindow]: null,
    [RoutePointFieldsEnum.isBrokerTimeWindow]: false,
};

export const DEFAULT_INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.shipperId]: null,
    [FieldsEnum.shipperName]: null,

    [FieldsEnum.trailerType]: DEFAULT_TRAILER_TYPE,
    [FieldsEnum.trailerDictId]: DEFAULT_TRAILER_DICT_ID[DEFAULT_TRAILER_TYPE],

    [FieldsEnum.laneId]: null,

    [FieldsEnum.excludedCountries]: [],

    [FieldsEnum.route]: [
        // init origin point
        { ...DEFAULT_ROUTE_POINT_INITIAL_VALUES },
        // init destination point
        { ...DEFAULT_ROUTE_POINT_INITIAL_VALUES },
    ],
};

const getInitialValues = (): FormValuesT => {
    const storageValues: Partial<FormValuesT> = storage.getValues() || {};

    const route = storageValues[FieldsEnum.route] || DEFAULT_INITIAL_VALUES[FieldsEnum.route];

    return {
        [FieldsEnum.shipperId]: storageValues[FieldsEnum.shipperId] || DEFAULT_INITIAL_VALUES[FieldsEnum.shipperId],
        [FieldsEnum.shipperName]:
            storageValues[FieldsEnum.shipperName] || DEFAULT_INITIAL_VALUES[FieldsEnum.shipperName],

        [FieldsEnum.trailerType]:
            storageValues[FieldsEnum.trailerType] || DEFAULT_INITIAL_VALUES[FieldsEnum.trailerType],
        [FieldsEnum.trailerDictId]:
            storageValues[FieldsEnum.trailerDictId] || DEFAULT_INITIAL_VALUES[FieldsEnum.trailerDictId],

        [FieldsEnum.laneId]: storageValues[FieldsEnum.laneId] || DEFAULT_INITIAL_VALUES[FieldsEnum.laneId],

        [FieldsEnum.excludedCountries]:
            storageValues[FieldsEnum.excludedCountries] || DEFAULT_INITIAL_VALUES[FieldsEnum.excludedCountries],

        [FieldsEnum.route]: route.map((routePoint) => {
            return {
                ...routePoint,
                [RoutePointFieldsEnum.timeWindow]: null,
            };
        }),
    };
};

const DEFAULT_TRAILERS_DICT = {
    [TrailerTypeEnum.tilt]: [],
    [TrailerTypeEnum.box]: [],
    // [TrailerTypeEnum.reef]: [],
};

const ShipmentDetailsForm: React.FC<PropsT> = React.memo((props) => {
    const { isBroker } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const orderCreationPercentContext = useContext(OrderCreationPercentContext);
    const syncShipmentDetailsFormValuesContext = React.useContext(SyncShipmentDetailsFormValuesContext);

    const stepsContext = useContext(StepsContext);

    const { trailersDict } = useTrailersDict();

    const createRFQStatus = useSelector(selectCreateRFQRequest);
    const createPriceOfferWithLaneStatus = useSelector(selectCreatePriceOfferWithLaneRequest);

    const reserve = useSelector(selectReserve);
    const reserveRequest = useSelector(selectReserveRequest);
    const reservePreview = useSelector(selectReservePreview);
    const reservePreviewRequest = useSelector(selectReservePreviewRequest);

    const shippersById = useSelector(selectShippersById);

    const countriesByCode = useCountriesByCode();

    const validate = React.useCallback(
        (values: FormValuesT) => {
            return validateForm(t, values, {
                isBroker,
                shippersById,
                countriesByCode,
            });
        },
        [t, shippersById, countriesByCode],
    );

    const syncShipmentDetailsFormMapStateContext = React.useContext(SyncShipmentDetailsFormMapStateContext);

    const [initialValues, initialTouched, initialErrors] = React.useMemo(() => {
        const values = getInitialValues();

        const allFields = Object.keys(values) as FieldsEnum[];

        const touched = allFields.reduce<FormTouchedT>((acc, key) => {
            if (key !== 'route') {
                acc[key] = DEFAULT_INITIAL_VALUES[key] !== values[key];
            }

            return acc;
        }, {});

        if (!isEqual(DEFAULT_INITIAL_VALUES?.route, values?.route)) {
            values?.route.forEach((routePointValue, index) => {
                const allRoutePointFields = Object.keys(routePointValue) as RoutePointFieldsEnum[];

                if (!touched.route) {
                    touched.route = [];
                }

                touched.route[index] = allRoutePointFields.reduce<NonNullable<FormTouchedT['route']>[number]>(
                    (acc, key) => {
                        acc[key] = DEFAULT_ROUTE_POINT_INITIAL_VALUES[key] !== routePointValue[key];

                        return acc;
                    },
                    {},
                );
            });
        }

        const errors = validateForm(t, values, {
            isBroker,
            shippersById,
            countriesByCode,
        });

        return [values, touched, errors];
    }, [isBroker, shippersById, countriesByCode]);

    React.useEffect(() => {
        const currentLocations = initialValues[FieldsEnum.route]?.map(
            (routePointValues) => routePointValues[RoutePointFieldsEnum.location],
        );

        const points = currentLocations.map((location) => location?.point || null);

        const boundingBox = getBoundingBox(points);

        if (syncShipmentDetailsFormMapStateContext?.setInitialBoundingBox) {
            syncShipmentDetailsFormMapStateContext.setInitialBoundingBox(boundingBox);
        }
    }, [initialValues, syncShipmentDetailsFormMapStateContext?.isMapInitialized]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        initialTouched,
        validate,
        onSubmit: (values, formikHelpers): void => {
            if (!reserve) {
                logWarning('empty reserve');
                return;
            }

            const apiActualOrderRequest = prepareApiActualOrderRequest(values, reserve);
            if (!apiActualOrderRequest) {
                logWarning('invalid apiActualOrderRequest', { values });
                return;
            }

            const shipperId = values[FieldsEnum.shipperId];

            const laneId = values[FieldsEnum.laneId];
            if (laneId) {
                dispatch(createPriceOfferWithLaneRequest(apiActualOrderRequest, reserve, shipperId, laneId));
            } else {
                dispatch(createRFQ(apiActualOrderRequest, reserve, shipperId));
            }

            formikHelpers.setTouched({});
        },
    });

    React.useEffect(() => {
        syncShipmentDetailsFormValuesContext.syncFormValues(formik.values);
    }, [formik.values]);

    React.useEffect(() => {
        syncShipmentDetailsFormValuesContext.syncSetFieldValueCallback((name, value) => {
            formik.setFieldValue(name, value);
            formik.setFieldTouched(name);
        });
    }, [formik.setFieldValue, formik.setFieldTouched]);

    React.useEffect(() => {
        dispatch(changeReserveQuery(prepareApiReserveChanges({ ...formik.values })));

        return () => {
            dispatch(resetReserveAndPreviewReserve());
        };
    }, []);

    // save values
    React.useEffect(() => {
        storage.saveValues(formik.values);
    }, [formik.values]);

    React.useEffect(() => {
        const editingRoutePointErrorsIndexes: Array<number> = [];

        formik.values[FieldsEnum.route]?.forEach((_, index) => {
            const fieldName = getRoutePointFieldName(index, RoutePointFieldsEnum.location);

            const hasError = !!get(formik.errors, fieldName);

            const hasValue = !!get(formik.values, fieldName);

            if (hasValue && hasError) {
                editingRoutePointErrorsIndexes.push(index);
            }
        });

        syncShipmentDetailsFormMapStateContext.setHasEditingRoutePointErrorIndexes(editingRoutePointErrorsIndexes);
    }, [formik.errors, formik.values]);

    React.useEffect(() => {
        const routeLength = formik.values[FieldsEnum.route]?.length || 0;

        const percent = getterFormFillPercent([
            FieldsEnum.trailerDictId,
            ...times(routeLength).flatMap((routeIndex) => {
                return [
                    getRoutePointFieldName(routeIndex, RoutePointFieldsEnum.location),
                    getRoutePointFieldName(routeIndex, RoutePointFieldsEnum.timeWindow),
                ];
            }),
        ])(formik.values);

        orderCreationPercentContext.setPercent(percent);
    }, [formik.values]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(changeReserveQuery({}));
    }, [isBroker, formik.values]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    useOnlyAvailableLane(formik);
    useSyncReserveChannels(formik);
    useEditRouteWatcher(formik);

    const selectedLaneId = formik.values[FieldsEnum.laneId];
    const hasSelectedLane = !!selectedLaneId;

    const selectedLane = useSelectedLane(selectedLaneId);

    useEffect(() => {
        stepsContext.setHasSelectedContract(hasSelectedLane);
    }, [hasSelectedLane]);

    const isLockedByReserve = useReserveChannelLock();

    const availableTrailersTypes = React.useMemo(() => {
        return keys(trailersDict || DEFAULT_TRAILERS_DICT) as Array<TrailerTypeEnum>;
    }, [trailersDict]);

    const selectedTrailerType = formik.values[FieldsEnum.trailerType];

    const handleChangeDictTrailerId = React.useCallback(
        (_: string, trailerDictId: TrailerDictIdT | null): void => {
            formik.setValues((prevValues) => ({
                ...prevValues,
                [FieldsEnum.trailerDictId]: trailerDictId,
            }));

            dispatch(
                changeReserveQuery(
                    prepareApiReserveChanges({
                        [FieldsEnum.trailerDictId]: trailerDictId,
                    }),
                ),
            );
        },
        [dispatch, formik],
    );

    const handleChangeLaneId = React.useCallback(
        (_: string, laneId: ShipperContractLaneIdT | null): void => {
            formik.setValues((prevValues) => ({
                ...prevValues,
                [FieldsEnum.laneId]: laneId,
            }));

            dispatch(
                changeReserveQuery(
                    prepareApiReserveChanges({
                        [FieldsEnum.laneId]: laneId,
                    }),
                ),
            );
        },
        [dispatch, formik],
    );

    // handle change traiiler type
    const handleChangeTrailerTypeValue = React.useCallback(
        (field: string, value: TrailerTypeEnum): void => {
            const trailerDictId = DEFAULT_TRAILER_DICT_ID[value];

            formik.setValues((prevValues) => ({
                ...prevValues,
                [FieldsEnum.trailerType]: value,
                [FieldsEnum.trailerDictId]: trailerDictId,
            }));

            dispatch(
                changeReserveQuery(
                    prepareApiReserveChanges({
                        [FieldsEnum.trailerType]: value,
                        [FieldsEnum.trailerDictId]: trailerDictId,
                    }),
                ),
            );
        },
        [dispatch, formik],
    );

    const handleExpire = useCallback((): void => {
        dispatch(changeReserveQuery({}));
    }, [dispatch]);

    const isAllowCreateRFQ = useSelector(selectIsAllowCreateRFQ);

    const [isAllowShowReplacedLocationsAlert, setAllowShowReplacedLocationsAlert] = React.useState<boolean>(true);
    const handleClosedReplacedLocationsAlert = useCallback((): void => {
        setAllowShowReplacedLocationsAlert(false);
    }, [setAllowShowReplacedLocationsAlert]);
    const hasPointsViolation = isAllowShowReplacedLocationsAlert && reserve?.points?.some((point) => point?.violated);
    const alertNode = hasPointsViolation ? (
        <ReplacedLocationsAlert onClose={handleClosedReplacedLocationsAlert} />
    ) : null;

    const isDisabledPreviewReserve =
        isLockedByReserve || reserveRequest.loading || !!reserveRequest.error || reservePreviewRequest.loading;

    const isShowSuggestLoading = reserveRequest.loading || reservePreviewRequest.loading;

    const isSubmitting = createRFQStatus.loading || createPriceOfferWithLaneStatus.loading;
    const isLoadingReservePreview = reserveRequest.loading || reservePreviewRequest.loading;

    const handleChangeRoutePointReserve: OnChangeRoutePointReserveT = useCallback(
        (routePointIndex, changes) => {
            const prevRoutePointValues = formik.values[FieldsEnum.route] || [];

            dispatch(
                changeReserveQuery(
                    prepareApiReserveChanges({
                        [FieldsEnum.route]: prepareApiRoutePointReserveChanges(
                            routePointIndex,
                            changes,
                            prevRoutePointValues,
                        ),
                    }),
                ),
            );
        },
        [dispatch, formik.values],
    );

    const handleManualSelectLocation = (location: LocationT | null, routePointIndex: number) => {
        if (!location) {
            return;
        }

        const currentLocations = formik.values[FieldsEnum.route]?.map(
            (routePointValues) => routePointValues[RoutePointFieldsEnum.location],
        );
        currentLocations.splice(routePointIndex, 1, location);

        const points = currentLocations.map((location) => location?.point || null);

        const boundingBox = getBoundingBox(points);

        if (syncShipmentDetailsFormMapStateContext?.syncMapSettings) {
            syncShipmentDetailsFormMapStateContext.syncMapSettings({
                center: null,
                zoom: null,
                boundingBox,
            });
        }
    };

    const isShowLaneSelectControl = !!reserve?.contractLanes?.length;

    const delayedFocusOnLocationInput = (pointIndex: number, fakeId: string) => {
        setTimeout(async () => {
            const locationName = getRoutePointFieldName(pointIndex, RoutePointFieldsEnum.location);
            const inputNodeSelector = `div[data-fake-id="${fakeId}"] input[name="${locationName}"]`;

            let inputNode = null;
            while (!inputNode) {
                inputNode = document.querySelector(inputNodeSelector);

                // @ts-expect-error
                if (inputNode?.focus) {
                    // @ts-expect-error
                    inputNode?.focus();
                }

                const DELAY = MS_IN_SEC;
                await new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, DELAY);
                });
            }
        }, 0);
    };

    return (
        <FormikContext.Provider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('wrap')} data-test-selector="new-order-shipment-details">
                    {isBroker && (
                        <div className={cx('block')}>
                            <div className={cx('block__title')}>{t('common:new-order-form.blocks.shipper.title')}</div>
                            <div className={cx('block__content')}>
                                <FormikField
                                    name={FieldsEnum.shipperId}
                                    error={formik.errors[FieldsEnum.shipperId]}
                                    meta={formik.getFieldMeta(FieldsEnum.shipperId)}
                                    label={t('common:new-order-form.fields.shipper.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <ShipperDropdown
                                            value={formik.values[FieldsEnum.shipperId]}
                                            initialValue={initialValues[FieldsEnum.shipperId]}
                                            initialLabel={initialValues[FieldsEnum.shipperName] || ''}
                                            hasClearControl
                                            onSelect={(shipperId, shipperName) => {
                                                formik.setValues((prevValues) => ({
                                                    ...prevValues,
                                                    [FieldsEnum.shipperId]: shipperId,
                                                    [FieldsEnum.shipperName]: shipperName,
                                                }));

                                                dispatch(
                                                    changeReserveQuery(
                                                        prepareApiReserveChanges({
                                                            [FieldsEnum.shipperId]: shipperId,
                                                        }),
                                                    ),
                                                );
                                            }}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                            placeholder={t('common:new-order-form.fields.shipper.placeholder')}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                        />
                                    )}
                                </FormikField>
                            </div>
                        </div>
                    )}
                    <div className={cx('block')}>
                        <div className={cx('block__content')}>
                            <div className={cx('field--trailer-type')}>
                                <TrailerTypeSwitcher
                                    value={formik.values[FieldsEnum.trailerType]}
                                    name={FieldsEnum.trailerType}
                                    availableTypes={availableTrailersTypes}
                                    setFieldValue={handleChangeTrailerTypeValue}
                                />
                            </div>
                            <div className={cx('field--trailer-mode')}>
                                <FormikField
                                    name={FieldsEnum.trailerDictId}
                                    error={formik.errors[FieldsEnum.trailerDictId]}
                                    meta={formik.getFieldMeta(FieldsEnum.trailerDictId)}
                                    label={t(`common:new-order-form.fields.trailer.${selectedTrailerType}.label`)}
                                    setFieldValue={handleChangeDictTrailerId}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <DictTrailerDropdown
                                            placeholder={t(
                                                `common:new-order-form.fields.trailer.${selectedTrailerType}.placeholder`,
                                            )}
                                            selectedTrailerType={formik.values[FieldsEnum.trailerType]}
                                            value={formik.values[FieldsEnum.trailerDictId]}
                                            onChange={props.onChange}
                                            hasWarning={props.hasWarning}
                                            hasError={props.hasError}
                                            onFocus={props.onFocus}
                                            onBlur={props.onBlur}
                                        />
                                    )}
                                </FormikField>
                            </div>
                        </div>
                    </div>
                    <FormikFieldArray<FormValuesT, typeof FieldsEnum.route> name={FieldsEnum.route}>
                        {(values, { remove, insert }) => {
                            return (
                                <>
                                    {values.map((value, index) => {
                                        const isFirst = index === 0;
                                        const isLast = index === values.length - 1;

                                        const isAllowRemove = !isFirst && !isLast;
                                        const routePointType = value[RoutePointFieldsEnum.type];
                                        const fakeId = value[RoutePointFieldsEnum.fakeId];

                                        let label = t('common:new-order-form.fields.pickup-delivery-point.label');
                                        if (isFirst) {
                                            label = t('common:new-order-form.fields.first-point.label');
                                        }
                                        if (isLast) {
                                            label = t('common:new-order-form.fields.last-point.label');
                                        }

                                        return (
                                            <>
                                                {routePointType === RoutePointTypeEnum.pickupOrDelivery && (
                                                    <PickupOrDeliveryRoutePointPicker
                                                        key={`point-${fakeId}`}
                                                        label={label}
                                                        routePointIndex={index}
                                                        reserve={reserve}
                                                        isShowSuggestLoading={isShowSuggestLoading}
                                                        isDisabledPreviewReserve={isDisabledPreviewReserve}
                                                        previewReserve={reservePreview}
                                                        onChangeRoutePointReserve={handleChangeRoutePointReserve}
                                                        onRemove={(index) => {
                                                            const newRoute = [...values];

                                                            newRoute.splice(index, 1);

                                                            dispatch(
                                                                changeReserveQuery(
                                                                    prepareApiReserveChanges({
                                                                        [FieldsEnum.route]: newRoute,
                                                                    }),
                                                                ),
                                                            );

                                                            remove(index);
                                                        }}
                                                        isAllowRemove={isAllowRemove}
                                                        onManualSelectLocation={handleManualSelectLocation}
                                                    />
                                                )}
                                                {routePointType === RoutePointTypeEnum.driveThrough && (
                                                    <DriveThroughRoutePointPicker
                                                        key={`point-${fakeId}`}
                                                        routePointIndex={index}
                                                        reserve={reserve}
                                                        isShowSuggestLoading={isShowSuggestLoading}
                                                        isDisabledPreviewReserve={isDisabledPreviewReserve}
                                                        onChangeRoutePointReserve={handleChangeRoutePointReserve}
                                                        onRemove={(index) => {
                                                            const newRoute = [...values];

                                                            newRoute.splice(index, 1);

                                                            dispatch(
                                                                changeReserveQuery(
                                                                    prepareApiReserveChanges({
                                                                        [FieldsEnum.route]: newRoute,
                                                                    }),
                                                                ),
                                                            );

                                                            remove(index);
                                                        }}
                                                        isAllowRemove={isAllowRemove}
                                                        onManualSelectLocation={handleManualSelectLocation}
                                                    />
                                                )}
                                                {!isLast && (
                                                    <DividerWithDropdownControl
                                                        key={`divider-with-dropdown-${index}`}
                                                        className={cx('route-points-divider')}
                                                        overlayPosition={DropdownOverlayPositionEnum.bottomCenter}
                                                        tooltipNode={t('common:new-order-form.route-editor.add-point')}
                                                        options={[
                                                            {
                                                                label: (
                                                                    <DropdownControlOptionLabel
                                                                        icon={
                                                                            <CircleIcon
                                                                                borderColor={
                                                                                    StyleGuideColorsEnum.charcoal
                                                                                }
                                                                            />
                                                                        }
                                                                        label={t(
                                                                            'common:new-order-form.route-editor.options.add-pickup-delivery-point',
                                                                        )}
                                                                    />
                                                                ),
                                                                onSelect: () => {
                                                                    const fakeId = uuidv4();

                                                                    const newRoutePoint: RoutePointFormValuesT = {
                                                                        [RoutePointFieldsEnum.fakeId]: fakeId,
                                                                        [RoutePointFieldsEnum.id]: null,
                                                                        [RoutePointFieldsEnum.type]:
                                                                            RoutePointTypeEnum.pickupOrDelivery,
                                                                        [RoutePointFieldsEnum.location]: null,
                                                                        [RoutePointFieldsEnum.timeWindow]: null,
                                                                        [RoutePointFieldsEnum.isBrokerTimeWindow]:
                                                                            false,
                                                                    };

                                                                    insert(index + 1, newRoutePoint);

                                                                    const newRoute = [...values];

                                                                    newRoute.splice(index + 1, 0, newRoutePoint);

                                                                    dispatch(
                                                                        changeReserveQuery(
                                                                            prepareApiReserveChanges({
                                                                                [FieldsEnum.route]: newRoute,
                                                                            }),
                                                                        ),
                                                                    );

                                                                    delayedFocusOnLocationInput(index + 1, fakeId);
                                                                },
                                                            },
                                                            {
                                                                label: (
                                                                    <DropdownControlOptionLabel
                                                                        icon={
                                                                            <CircleIcon
                                                                                borderColor={StyleGuideColorsEnum.gray}
                                                                            />
                                                                        }
                                                                        label={t(
                                                                            'common:new-order-form.route-editor.options.add-driver-through-point',
                                                                        )}
                                                                    />
                                                                ),
                                                                onSelect: () => {
                                                                    const fakeId = uuidv4();

                                                                    const newRoutePoint: RoutePointFormValuesT = {
                                                                        [RoutePointFieldsEnum.fakeId]: fakeId,
                                                                        [RoutePointFieldsEnum.id]: null,
                                                                        [RoutePointFieldsEnum.type]:
                                                                            RoutePointTypeEnum.driveThrough,
                                                                        [RoutePointFieldsEnum.location]: null,
                                                                        [RoutePointFieldsEnum.timeWindow]: null,
                                                                        [RoutePointFieldsEnum.isBrokerTimeWindow]:
                                                                            false,
                                                                    };

                                                                    insert(index + 1, newRoutePoint);

                                                                    const newRoute = [...values];

                                                                    newRoute.splice(index + 1, 0, newRoutePoint);

                                                                    dispatch(
                                                                        changeReserveQuery(
                                                                            prepareApiReserveChanges({
                                                                                [FieldsEnum.route]: newRoute,
                                                                            }),
                                                                        ),
                                                                    );

                                                                    delayedFocusOnLocationInput(index + 1, fakeId);
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                )}
                                            </>
                                        );
                                    })}
                                </>
                            );
                        }}
                    </FormikFieldArray>
                    {isShowLaneSelectControl && <div className={cx('lane-control-placeholder')} />}
                </div>
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
                <FooterSideBarLayout isAbsolute hasPaddings topNode={alertNode}>
                    {isShowLaneSelectControl && (
                        <FormikField
                            name={FieldsEnum.laneId}
                            error={formik.errors[FieldsEnum.laneId]}
                            meta={formik.getFieldMeta(FieldsEnum.laneId)}
                            label={t('common:new-order-form.fields.lane.label')}
                            setFieldValue={handleChangeLaneId}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <LaneDropdownInput
                                    selectedValue={formik.values[FieldsEnum.laneId]}
                                    placeholder={t('common:new-order-form.fields.lane.placeholder')}
                                    onSelect={props.onChange}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onFocus={props.onFocus}
                                    onBlur={props.onBlur}
                                    isLoading={reserveRequest.loading}
                                    options={reserve?.contractLanes}
                                    overlayPosition={DropdownOverlayPositionEnum.topCenter}
                                    onReset={() => {
                                        props.onChange(null);
                                    }}
                                />
                            )}
                        </FormikField>
                    )}
                    <Button
                        isFullWidth
                        theme={ButtonThemeEnum.primary}
                        isDisabled={isSubmitting || isLoadingReservePreview || !isAllowCreateRFQ}
                        type="submit"
                        testSelector="next-step"
                        leftIcon={<div />}
                        rightIcon={
                            <ButtonTimer
                                showTime={MS_IN_MIN}
                                expireTime={reserve?.expireTime || null}
                                tooltipMessage={t('common:new-order-form.reserve-timer.tooltip')}
                                onExpire={handleExpire}
                            />
                        }
                    >
                        <SubmitButtonContent
                            hasReservePreview={!!reserve && !!reservePreview}
                            isSubmitting={isSubmitting}
                            isLoadingReservePreview={isLoadingReservePreview}
                            selectedLane={selectedLane}
                        />
                    </Button>
                </FooterSideBarLayout>
            </form>
        </FormikContext.Provider>
    );
});
export default ShipmentDetailsForm;
