import React from 'react';
import classNames from 'classnames/bind';

import styles from './OrdersFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import DestinationIcon from 'common/icons/DestinationIcon';
import OriginIcon from 'common/icons/OriginIcon';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import getQueryFilters from 'shipper/layouts/SideBars/OrdersFiltersSidebarContent/get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import UserDropdown from 'common/components/dropdowns/UserDropdown/UserDropdown';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { useQueryParam } from 'use-query-params';
import { OrdersFilterSidebarDataT } from 'shipper/layouts/SideBars/OrdersFiltersSidebarContent/models';
import { QueryFiltersT, QueryKeysEnum } from 'shipper/layouts/OrdersPage/OrderListPage/query-models';
import { createJsonParams } from 'common/utils/query';
import { ShipperSidebarDataT } from 'shipper/layouts/SideBars/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { QueryFiltersKeysEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<OrdersFilterSidebarDataT, ShipperSidebarDataT>;

const FiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.ordersFilters,
        createJsonParams({}),
    );

    const initialValues = React.useMemo(() => {
        return getInitialValues(queryFilters);
    }, [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilterChanges = getQueryFilters(values);

            setQueryFilters({
                ...queryFilterChanges,
                [QueryFiltersKeysEnum.quickFilter]: queryFilters[QueryFiltersKeysEnum.quickFilter],
            });

            if (onClose) {
                onClose();
            }

            formikHelpers.setTouched({});
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values);
    }, [initialValues, formik.values]);

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent
                    testSelector="filters"
                    title={t('orders-table.filters.sidebar.title')}
                    onClose={onClose}
                />
            </HeaderSideBarLayout>
            <SideBarLayout testSelector="filters">
                <div className={cx('block')}>
                    <div className={cx('block__title')}>{t('orders-table.filters.sidebar.blocks.truck')}</div>
                    <FormikField
                        name={FieldsEnum.dictTrailerId}
                        error={formik.errors[FieldsEnum.dictTrailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.dictTrailerId)}
                        label={t('orders-table.filters.sidebar.fields.trailer-type.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DictTrailerDropdown
                                placeholder={t('orders-table.filters.sidebar.fields.trailer-type.placeholder')}
                                value={formik.values[FieldsEnum.dictTrailerId]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('block')}>
                    <div className={cx('block__title')}>{t('orders-table.filters.sidebar.blocks.addresses')}</div>
                    <FormikField
                        name={FieldsEnum.pickupAddress}
                        error={formik.errors[FieldsEnum.pickupAddress]}
                        meta={formik.getFieldMeta(FieldsEnum.pickupAddress)}
                        label={t('orders-table.filters.sidebar.fields.pickup-address.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                renderLeftIcon={() => <OriginIcon />}
                                name={FieldsEnum.pickupAddress}
                                value={formik.values[FieldsEnum.pickupAddress]}
                                placeholder={t('orders-table.filters.sidebar.fields.pickup-address.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                testSelectorPrefix="origin"
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.dropoffAddress}
                        error={formik.errors[FieldsEnum.dropoffAddress]}
                        meta={formik.getFieldMeta(FieldsEnum.dropoffAddress)}
                        label={t('orders-table.filters.sidebar.fields.dropoff-address.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                renderLeftIcon={() => <DestinationIcon />}
                                name={FieldsEnum.dropoffAddress}
                                value={formik.values[FieldsEnum.dropoffAddress]}
                                placeholder={t('orders-table.filters.sidebar.fields.dropoff-address.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                testSelectorPrefix="destination"
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('block')}>
                    <div className={cx('block__title')}>{t('orders-table.filters.sidebar.blocks.created')}</div>
                    <FormikField
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('orders-table.filters.sidebar.fields.created-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('orders-table.filters.sidebar.fields.created-dates.placeholder')}
                                onChange={props.onChange}
                                testSelectorPrefix="create-dates"
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.createdById}
                        error={formik.errors[FieldsEnum.createdById]}
                        meta={formik.getFieldMeta(FieldsEnum.createdById)}
                        label={t('orders-table.filters.sidebar.fields.created-by.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <UserDropdown
                                value={formik.values[FieldsEnum.createdById]}
                                onChange={(createdById, createdByName) => {
                                    formik.setValues((prevValues) => {
                                        return {
                                            ...prevValues,
                                            [FieldsEnum.createdByName]: createdByName || null,
                                            [FieldsEnum.createdById]: createdById,
                                        };
                                    });
                                }}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                companyId={partnerId}
                                placeholder={t('orders-table.filters.sidebar.fields.created-by.placeholder')}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </div>
                <div className={cx('block', 'block--isLast')}>
                    <div className={cx('block__title')}>{t('orders-table.filters.sidebar.blocks.dates')}</div>
                    <FieldGroup>
                        <FormikField
                            className={cx('field--dates')}
                            name={FieldsEnum.pickupDates}
                            error={formik.errors[FieldsEnum.pickupDates]}
                            meta={formik.getFieldMeta(FieldsEnum.pickupDates)}
                            label={t('orders-table.filters.sidebar.fields.pickup-dates.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DatePicker
                                    isRange
                                    value={formik.values[FieldsEnum.pickupDates]}
                                    placeholder={t('orders-table.filters.sidebar.fields.pickup-dates.placeholder')}
                                    onChange={props.onChange}
                                    overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                    testSelectorPrefix="pickup-dates"
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field--dates')}
                            name={FieldsEnum.dropoffDates}
                            error={formik.errors[FieldsEnum.dropoffDates]}
                            meta={formik.getFieldMeta(FieldsEnum.dropoffDates)}
                            label={t('orders-table.filters.sidebar.fields.dropoff-dates.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <DatePicker
                                    isRange
                                    value={formik.values[FieldsEnum.dropoffDates]}
                                    placeholder={t('orders-table.filters.sidebar.fields.dropoff-dates.placeholder')}
                                    onChange={props.onChange}
                                    overlayPosition={DatePickerOverlayPositionEnum.topRight}
                                    testSelectorPrefix="dropoff-dates"
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                    {/* <TimeWindowPicker */}
                    {/*    className={cx('time-picker')} */}
                    {/*    range={DAY_RANGE} */}
                    {/*    availableValues={DAY_RANGE} */}
                    {/*    ranges={[]} */}
                    {/*    name={FieldsEnum.pickupTimeWindow} */}
                    {/*    hasStartStep */}
                    {/*    hasEndStep */}
                    {/*    values={formik.values[FieldsEnum.pickupTimeWindow] as TimeWindowT} */}
                    {/*    setFieldValue={formik.setFieldValue} */}
                    {/*    label={t('orders-table.filters.sidebar.fields.pickup-time-window.label')} */}
                    {/*    testSelector="pickup-time-window" */}
                    {/* /> */}
                    {/* <TimeWindowPicker */}
                    {/*    className={cx('time-picker')} */}
                    {/*    range={DAY_RANGE} */}
                    {/*    availableValues={DAY_RANGE} */}
                    {/*    ranges={[]} */}
                    {/*    name={FieldsEnum.dropoffTimeWindow} */}
                    {/*    hasStartStep */}
                    {/*    hasEndStep */}
                    {/*    values={formik.values[FieldsEnum.dropoffTimeWindow] as TimeWindowT} */}
                    {/*    setFieldValue={formik.setFieldValue} */}
                    {/*    label={t('orders-table.filters.sidebar.fields.dropoff-time-window.label')} */}
                    {/*    testSelector="dropoff-time-window" */}
                    {/* /> */}
                </div>
            </SideBarLayout>
            <FooterSideBarLayout isTransparent hasPaddings>
                <Button
                    isDisabled={isSameValues}
                    theme={ButtonThemeEnum.primary}
                    className={cx('button')}
                    testSelector="apply-filters"
                    type="submit"
                >
                    {t('orders-table.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default FiltersSidebarContent;
