import { ADDITIONAL_SERVICES } from 'common/constants';

const getDefaultAdditionalServices = (isBrokerWindow: boolean): number[] => {
    const services = [ADDITIONAL_SERVICES.EURO6, ADDITIONAL_SERVICES.EURO5];

    if (isBrokerWindow) {
        services.push(ADDITIONAL_SERVICES.TIME_SLOTS_APPOINTMENT_BY_BROKER);
    }

    return services;
};

export { getDefaultAdditionalServices };
