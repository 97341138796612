import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './LaneDropdownInput.scss';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import { ApiContractLaneT } from 'common/utils/api/models';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import keyBy from 'lodash/keyBy';
import { useTranslation } from 'react-i18next';
import LaneDropdownInputOptionLabel from 'common/layouts/NewOrderPage/ShipmentDetailsForm/LaneDropdownInput/LaneDropdownInputOptionLabel/LaneDropdownInputOptionLabel';

const cx = classNames.bind(styles);

type ValueT = ApiContractLaneT['tztLaneId'] | null;
type OptionT = ApiContractLaneT;

type PropsT = {
    options?: Array<OptionT>;
    isLoading?: boolean;

    selectedValue: ValueT;
    placeholder: string;
    isDisabled?: boolean;
    onSelect: (value: ValueT, label: string) => void;
    overlayPosition?: DropdownOverlayPositionEnum;
    className?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    onReset?: () => void;
};

const EMPTY_OPTIONS: Array<OptionT> = [];

const LaneDropdownInput: React.FC<PropsT> = React.memo((props) => {
    const {
        isLoading,

        selectedValue,
        placeholder,
        isDisabled,
        onSelect,
        overlayPosition,
        className,
        hasError,
        hasWarning,
        hasChanges,
        onBlur,
        onFocus,
        onReset,
    } = props;

    const options = props.options || EMPTY_OPTIONS;

    const { t } = useTranslation();

    const getOptionLabel = (option: OptionT | null | undefined): string => {
        if (!option) {
            return '';
        }

        return t('common:new-order-form.lane-option-template', {
            contractName: option.contractName || '-',
            tztLaneId: option.tztLaneId || '-',
            externalLaneId: option.externalLaneId || '-',
        });
    };

    const optionsById = useMemo(() => {
        return keyBy(options, 'id');
    }, [options]);

    const renderTrigger = (option?: OptionT, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return getOptionLabel(option);
    };

    const renderOption = (option?: OptionT, placeholder?: string): React.ReactNode => {
        if (!option) {
            return placeholder;
        }

        return <LaneDropdownInputOptionLabel lane={option} />;
    };

    const getOptionValue = (option: OptionT): ValueT => option.id;

    const handleSelect = (value: ValueT) => {
        const selectedOption = optionsById[value as string];
        const selectedOptionLabel = getOptionLabel(selectedOption);
        onSelect(value, selectedOptionLabel);
    };

    return (
        <DropdownInput<OptionT, ValueT>
            className={className}
            selectedValue={selectedValue}
            placeholder={placeholder}
            options={options}
            isDisabled={isLoading || isDisabled}
            onSelect={handleSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            renderRightIcon={() =>
                isLoading ? <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} /> : null
            }
            renderOption={renderOption}
            renderTrigger={renderTrigger}
            getOptionValue={getOptionValue}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasClearControl={!!onReset}
            onReset={onReset}
        />
    );
});

export default LaneDropdownInput;
