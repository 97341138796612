import { ApiReservePreviewT, ReservePreviewT, ReserveT } from '../models';
import keyBy from 'lodash/keyBy';

const prepareReservePreview = (apiReservePreview: ApiReservePreviewT, reserve: ReserveT | null): ReservePreviewT => {
    const timeWindowsById = keyBy(apiReservePreview.timeWindows, (timeWindow) => timeWindow?.id);
    const points = reserve?.points || [];

    return {
        ...apiReservePreview,
        timeWindows: points.map((point) => {
            const timeWindow = timeWindowsById[point.id];
            if (!timeWindow) {
                return {
                    id: point.id,
                    correctedTimeWindow: null,
                    urgentTimeWindow: null,
                    regularTimeWindow: null,
                    layoverTimeWindow: null,
                };
            }

            const { correctedFrom, correctedTo, regularFrom, urgentFrom, layoverStart } = timeWindow;

            return {
                id: timeWindow.id,
                correctedTimeWindow:
                    correctedFrom && correctedTo
                        ? {
                              start: correctedFrom,
                              end: correctedTo,
                          }
                        : null,
                urgentTimeWindow:
                    urgentFrom && regularFrom && urgentFrom !== regularFrom
                        ? {
                              start: urgentFrom,
                              end: regularFrom,
                          }
                        : null,
                regularTimeWindow: regularFrom
                    ? {
                          start: regularFrom,
                          end: layoverStart || null,
                      }
                    : null,
                layoverTimeWindow: layoverStart
                    ? {
                          start: layoverStart,
                          end: null,
                      }
                    : null,
            };
        }),
    };
};

export default prepareReservePreview;
