import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork, ForkEffect } from 'redux-saga/effects';

import authSaga from 'common/store/auth/sagas';
import userSaga from 'common/store/user/sagas';
import companySaga from 'common/store/company/sagas';
import orderCreationSaga from 'common/store/order-creation/sagas';
import additionalServicesDictSaga from 'common/store/additional-services-dict/sagas';
import ordersSaga from 'common/store/orders/sagas';
import ordersStatsSaga from 'common/store/orders-stats/sagas';
import countriesDictSaga from 'common/store/countries-dict/sagas';
import trailersDictSaga from 'common/store/trailers-dict/sagas';
import trucksDictSaga from 'common/store/trucks-dict/sagas';
import orderDetailsSaga from 'common/store/order-details/sagas';
import errorsSaga from 'common/store/errors/sagas';
import membersSaga from 'common/store/members/sagas';
import documentsDictSaga from 'common/store/documents-dict/sagas';
import documentsSaga from 'common/store/documents/sagas';
import notificationsSaga from 'common/store/notifications/sagas';
import notificationsAffectsSaga from './notifications/sagas';
import assetTrackSaga from 'common/store/asset-track/sagas';
import groupedNotificationCountsSaga from 'common/store/grouped-notification-counts/sagas';
import alertsSaga from 'common/store/alerts/sagas';
import settingsSaga from 'common/store/settings/sagas';
import rfqsSaga from 'common/store/rfqs/sagas';
import rfqsStatsSaga from 'common/store/rfqs-stats/sagas';
import rfqDetailsSaga from 'common/store/rfq-details/sagas';
import rfqsChangesSaga from 'common/store/rfqs-changes/sagas';
import carrierContractsSaga from 'common/store/carrier-contracts/sagas';
import { invoicesSaga } from './invoices/sagas';

import authReducer from 'common/store/auth/reducer';
import userReducer from 'common/store/user/reducer';
import companyReducer from 'common/store/company/reducer';
import errorsReducer from 'common/store/errors/reducer';
import orderCreationReducer from 'common/store/order-creation/reducer';
import additionalServicesDictReducer from 'common/store/additional-services-dict/reducer';
import ordersReducer from 'common/store/orders/reducer';
import ordersStatsReducer from 'common/store/orders-stats/reducer';
import countriesDictReducer from 'common/store/countries-dict/reducer';
import trailersDictReducer from 'common/store/trailers-dict/reducer';
import trucksDictReducer from 'common/store/trucks-dict/reducer';
import orderDetailsReducer from 'common/store/order-details/reducer';
import membersReducer from 'common/store/members/reducer';
import documentsDictReducer from 'common/store/documents-dict/reducer';
import documentsReducer from 'common/store/documents/reducer';
import notificationsReducer from 'common/store/notifications/reducer';
import assetTrackReducer from 'common/store/asset-track/reducer';
import groupedNotificationCountsReducer from 'common/store/grouped-notification-counts/reducer';
import alertsReducer from 'common/store/alerts/reducer';
import settingsReducer from 'common/store/settings/reducer';
import rfqsReducer from 'common/store/rfqs/reducer';
import rfqsStatsReducer from 'common/store/rfqs-stats/reducer';
import rfqDetailsReducer from 'common/store/rfq-details/reducer';
import rfqsChangesReducer from 'common/store/rfqs-changes/reducer';
import carrierContractsReducer from 'common/store/carrier-contracts/reducer';
import shipperContractLanesReducer from 'common/store/shipper-contract-lanes/slice';
import shipperContractLaneDetailsReducer from 'common/store/shipper-contract-lane-details/slice';
import routingGeometryReducer from 'common/store/routing-geometry/slice';
import stateMachineReducer from 'common/store/state-machine/slice';
import invoicesReducer from './invoices/reducer';

import { CompanyTypeEnum } from 'common/constants';
import legalFormsDictReducer from 'common/store/legal-forms-dict/reducer';
import legalFormsDictSaga from 'common/store/legal-forms-dict/sagas';
import { AuthProfileTypeEnum } from 'common/store/auth/constants';
import shipperContractsReducer from 'common/store/shipper-contracts/slice';
import shipperContractDetailsReducer from 'common/store/shipper-contract-details/slice';
import { shipperContractsSaga } from 'common/store/shipper-contracts/sagas';
import { shipperContractDetailsSaga } from 'common/store/shipper-contract-details/sagas';
import { shipperContractLanesSaga } from 'common/store/shipper-contract-lanes/sagas';
import { shipperContractLaneDetailsSaga } from 'common/store/shipper-contract-lane-details/sagas';
import { stateMachineSaga } from 'common/store/state-machine/sagas';
import { routingGeometrySaga } from 'common/store/routing-geometry/sagas';

const rootReducer = combineReducers({
    auth: authReducer(AuthProfileTypeEnum.shipper),
    user: userReducer,
    company: companyReducer,
    errors: errorsReducer,
    // shipper has not contracts, but only selectors
    carrierContracts: carrierContractsReducer,
    orderCreation: orderCreationReducer,
    additionalServicesDict: additionalServicesDictReducer,
    orders: ordersReducer,
    ordersStats: ordersStatsReducer,
    countriesDict: countriesDictReducer,
    trailersDict: trailersDictReducer,
    trucksDict: trucksDictReducer,
    orderDetails: orderDetailsReducer,
    legalFormsDict: legalFormsDictReducer,
    members: membersReducer,
    documentsDict: documentsDictReducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
    assetTrack: assetTrackReducer,
    groupedNotificationCounts: groupedNotificationCountsReducer,
    alerts: alertsReducer,
    settings: settingsReducer,
    rfqs: rfqsReducer,
    rfqsStats: rfqsStatsReducer,
    rfqDetails: rfqDetailsReducer,
    rfqsChanges: rfqsChangesReducer,
    invoices: invoicesReducer,
    shipperContracts: shipperContractsReducer,
    shipperContractDetails: shipperContractDetailsReducer,
    shipperContractLanes: shipperContractLanesReducer,
    shipperContractLaneDetails: shipperContractLaneDetailsReducer,
    routingGeometry: routingGeometryReducer,
    stateMachine: stateMachineReducer,
});

export type RootStateT = ReturnType<typeof rootReducer>;

function* rootSaga(): Generator<ForkEffect<TODO>, void, TODO> {
    yield fork(authSaga, CompanyTypeEnum.shipper);
    yield fork(userSaga);
    yield fork(companySaga);
    yield fork(orderCreationSaga, CompanyTypeEnum.shipper);
    yield fork(additionalServicesDictSaga);
    yield fork(ordersSaga);
    yield fork(ordersStatsSaga);
    yield fork(countriesDictSaga);
    yield fork(trailersDictSaga);
    yield fork(trucksDictSaga);
    yield fork(orderDetailsSaga);
    yield fork(legalFormsDictSaga);
    yield fork(errorsSaga);
    yield fork(membersSaga);
    yield fork(documentsDictSaga);
    yield fork(documentsSaga);
    yield fork(notificationsSaga);
    yield fork(notificationsAffectsSaga);
    yield fork(assetTrackSaga, CompanyTypeEnum.shipper);
    yield fork(groupedNotificationCountsSaga);
    yield fork(alertsSaga);
    yield fork(settingsSaga);
    yield fork(rfqsSaga);
    yield fork(rfqsStatsSaga);
    yield fork(rfqDetailsSaga, CompanyTypeEnum.shipper);
    yield fork(rfqsChangesSaga);
    // shipper has not contracts, but only selectors
    yield fork(carrierContractsSaga);
    yield fork(invoicesSaga);
    yield fork(shipperContractsSaga, CompanyTypeEnum.shipper);
    yield fork(shipperContractDetailsSaga, CompanyTypeEnum.shipper);
    yield fork(shipperContractLanesSaga, CompanyTypeEnum.shipper);
    yield fork(shipperContractLaneDetailsSaga, CompanyTypeEnum.shipper);
    yield fork(routingGeometrySaga, CompanyTypeEnum.shipper);
    yield fork(stateMachineSaga, CompanyTypeEnum.shipper);
}

const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
        console.error('[redux-saga] uncatched error');
        console.error(error);
    },
});

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

// for dev
window.store = store;

export default store;
