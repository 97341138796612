import { FieldsEnum, FormValuesT, ShipmentFieldsEnum } from 'common/layouts/NewOrderPage/CargoDetailsForm/constants';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import { TemperatureRangesValidationResultT } from 'common/layouts/NewOrderPage/CargoDetailsForm/TemperatureRangesCompatibleValidationAlert/TemperatureRangesCompatibleValidationAlert';

type ValidateMetaT = {
    trailerType: ApiTrailerDictTypeT | null | undefined;
};

export const checkTemperatureRangesCompatible = (
    formValues: FormValuesT,
    meta: ValidateMetaT,
): TemperatureRangesValidationResultT | null => {
    if (!meta?.trailerType?.reefer) {
        return null;
    }

    const shipments = formValues[FieldsEnum.shipments] || [];
    const firstShipment = shipments?.[0] || null;

    if (!firstShipment) {
        return null;
    }

    let minTemperature = firstShipment[ShipmentFieldsEnum.cargoTemperatureRange][0];
    let maxTemperature = firstShipment[ShipmentFieldsEnum.cargoTemperatureRange][1];

    shipments.forEach((shipment) => {
        minTemperature = Math.max(minTemperature, shipment[ShipmentFieldsEnum.cargoTemperatureRange][0]);
        maxTemperature = Math.min(maxTemperature, shipment[ShipmentFieldsEnum.cargoTemperatureRange][1]);
    });

    const isCompatible = minTemperature <= maxTemperature;
    return isCompatible ? null : {};
};
