import { FieldsEnum, FormValuesT } from './constants';

const getInitialValues = (): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.dictTrailerId]: null,

        [FieldsEnum.address]: null,

        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.dropoffDates]: null,

        [FieldsEnum.createDates]: null,
        [FieldsEnum.createdByName]: null,
        [FieldsEnum.createdById]: null,

        [FieldsEnum.source]: null,
    };

    return values;
};

export default getInitialValues;
