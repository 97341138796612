import React from 'react';
import classNames from 'classnames/bind';

import styles from './FiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { QueryFiltersT } from 'common/models';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup, { FieldGroupEmptyItem } from 'common/components/FieldGroup/FieldGroup';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import Input from 'common/components/Input/Input';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import { QueryFiltersKeysEnum } from 'common/constants';
import { RFQSourceEnum } from 'common/utils/api/models';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import PinIcon, { PinIconProps } from 'common/icons/PinIcon';

import { mergeQueryFilters } from 'common/utils/query';
import UserDropdown from 'common/components/dropdowns/UserDropdown/UserDropdown';
import SelectedFilters from '../SelectedFilters/SelectedFilters';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

type SourceTypeOptionT = { label: string; value: RFQSourceEnum | null };

const FiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose, setQueryFilters, queryFilters } = props;

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);

    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(), []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const newQueryFilters = getQueryFilters(values);
            const mergedQueryFilters = mergeQueryFilters(initialQueryFilters, newQueryFilters);

            if (mergedQueryFilters[QueryFiltersKeysEnum.address]) {
                delete mergedQueryFilters[QueryFiltersKeysEnum.searchText];
            }

            setQueryFilters(mergedQueryFilters);

            formikHelpers.setTouched({});
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const sourceOptions: Array<SourceTypeOptionT> = React.useMemo(
        () => [
            {
                label: t('common:rfqs-page.table.filters.source-type.all'),
                value: null,
            },
            {
                label: t('common:rfqs-page.table.filters.source-type.imported'),
                value: RFQSourceEnum.imported,
            },
            {
                label: t('common:rfqs-page.table.filters.source-type.internal'),
                value: RFQSourceEnum.internal,
            },
        ],
        [],
    );

    const hasAddressValue = !!formik.values[FieldsEnum.address];

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:rfqs-page.table.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field', 'field--trailer-type')}
                    name={FieldsEnum.dictTrailerId}
                    error={formik.errors[FieldsEnum.dictTrailerId]}
                    meta={formik.getFieldMeta(FieldsEnum.dictTrailerId)}
                    label={t('common:rfqs-page.table.filters.fields.trailer-type.value')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DictTrailerDropdown
                            placeholder={t('common:rfqs-page.table.filters.fields.trailer-type.placeholder')}
                            value={formik.values[FieldsEnum.dictTrailerId]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.address}
                    error={formik.errors[FieldsEnum.address]}
                    meta={formik.getFieldMeta(FieldsEnum.address)}
                    label={t('common:rfqs-page.table.filters.fields.address.value')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                            name={FieldsEnum.address}
                            value={formik.values[FieldsEnum.address] || ''}
                            placeholder={t('common:rfqs-page.table.filters.fields.address.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.pickupDates}
                        error={formik.errors[FieldsEnum.pickupDates]}
                        meta={formik.getFieldMeta(FieldsEnum.pickupDates)}
                        label={t('common:rfqs-page.table.filters.fields.pickup-dates.value')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.pickupDates]}
                                placeholder={t('common:rfqs-page.table.filters.fields.pickup-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.dropoffDates}
                        error={formik.errors[FieldsEnum.dropoffDates]}
                        meta={formik.getFieldMeta(FieldsEnum.dropoffDates)}
                        label={t('common:rfqs-page.table.filters.fields.drop-off-dates.value')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.dropoffDates]}
                                placeholder={t('common:rfqs-page.table.filters.fields.drop-off-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.topRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('common:rfqs-page.table.filters.fields.create-dates.value')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('common:rfqs-page.table.filters.fields.create-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--created')}
                        name={FieldsEnum.createdById}
                        error={formik.errors[FieldsEnum.createdById]}
                        meta={formik.getFieldMeta(FieldsEnum.createdById)}
                        label={t('common:rfqs-page.table.filters.fields.created-by.value')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <UserDropdown
                                value={formik.values[FieldsEnum.createdById]}
                                onChange={(createdById, createdByName) => {
                                    formik.setValues((prevValues) => {
                                        return {
                                            ...prevValues,
                                            [FieldsEnum.createdByName]: createdByName || null,
                                            [FieldsEnum.createdById]: createdById,
                                        };
                                    });
                                }}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                companyId={partnerId}
                                placeholder={t('common:rfqs-page.table.filters.fields.created-by.placeholder')}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--source')}
                        name={FieldsEnum.source}
                        error={formik.errors[FieldsEnum.source]}
                        meta={formik.getFieldMeta(FieldsEnum.source)}
                        label={t('common:rfqs-page.table.filters.fields.source.value')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownInput<SourceTypeOptionT, SourceTypeOptionT['value']>
                                selectedValue={formik.values[FieldsEnum.source]}
                                placeholder=""
                                options={sourceOptions}
                                onSelect={props.onChange}
                                renderOption={(option: SourceTypeOptionT | null | undefined, placeholder) => {
                                    if (!option) {
                                        return <span>{placeholder}</span>;
                                    }

                                    return <span>{option.label}</span>;
                                }}
                                getOptionValue={(option) => option.value}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                            />
                        )}
                    </FormikField>
                    <FieldGroupEmptyItem className={cx('field', 'field--empty')} />
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:rfqs-page.table.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default FiltersSidebarContent;
