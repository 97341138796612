import { ReservePreviewQueryT, ReservePreviewT, ReserveT } from 'common/store/order-creation/models';
import keyBy from 'lodash/keyBy';

export const getPreviewReserveCorrection = (
    query: ReservePreviewQueryT | null,
    previewReserve: ReservePreviewT | null,
    reserve: ReserveT | null,
): ReservePreviewQueryT | null => {
    if (!previewReserve || !query) {
        return null;
    }

    const hasAnyCorrection = previewReserve.timeWindows?.some((timeWindow) => {
        return !!timeWindow.correctedTimeWindow;
    });
    if (!hasAnyCorrection) {
        return null;
    }

    const timeWindows: ReservePreviewQueryT['timeWindows'] = [];

    const points = reserve?.points || [];
    const timeWindowsById = keyBy(previewReserve?.timeWindows, (timeWindow) => timeWindow?.id);
    const queryTimeWindowsById = keyBy(query?.timeWindows, (timeWindow) => timeWindow?.id || '');

    points.forEach((point) => {
        const timeWindow = timeWindowsById[point.id];
        if (!timeWindow) {
            return;
        }

        const queryTimeWindow = queryTimeWindowsById[point.id];

        timeWindows.push({
            id: point.id,
            isBrokerTimeWindow: queryTimeWindow?.isBrokerTimeWindow || false,
            timeWindowFrom: timeWindow?.correctedTimeWindow?.start || queryTimeWindow?.timeWindowFrom,
            timeWindowTo: timeWindow?.correctedTimeWindow?.end || queryTimeWindow?.timeWindowTo,
        });
    });

    return {
        ...query,
        timeWindows,
    };
};
