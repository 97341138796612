import { useMemo } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { isNonNil } from 'common/utils';
import pickBy from 'lodash/pickBy';

export const useCheckHasOnlyQueryFilters = (sourceQueryFilters: Record<string, any>, queryKeys: Array<string>) => {
    return useMemo(() => {
        const queryFilters = pickBy(sourceQueryFilters, isNonNil);
        if (isEmpty(queryFilters)) {
            return false;
        }

        const restQueryFilters = omit(queryFilters, queryKeys);
        if (isEmpty(restQueryFilters)) {
            return true;
        }

        return false;
    }, [sourceQueryFilters, queryKeys]);
};
