import React from 'react';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';

type PropsT = {
    hasSelectedFilters: boolean;
    hasVerificationProblem: boolean;
    hasSelectedStatus: boolean;
    goToProfile: () => void;
    goToCreateOrderForm: () => void;
    clearAllFilters: () => void;
};

const EmptyMessage: React.FC<PropsT> = (props) => {
    const {
        hasSelectedFilters,
        hasVerificationProblem,
        hasSelectedStatus,
        goToProfile,
        goToCreateOrderForm,
        clearAllFilters,
    } = props;

    const { t } = useTranslation();

    if (hasSelectedFilters) {
        return (
            <TableMessage
                testSelector="not-found"
                iconType={TableMessageIconsEnum.notFound}
                title={t('orders-table.messages.not-found.title')}
                description={t('orders-table.messages.not-found.description')}
                isShowAction
                actionTitle={t('orders-table.messages.not-found.action')}
                actionTheme={ButtonThemeEnum.secondary}
                onActionClick={clearAllFilters}
            />
        );
    }

    if (hasSelectedStatus) {
        return (
            <TableMessage
                testSelector="empty"
                iconType={TableMessageIconsEnum.empty}
                title={t('orders-table.messages.not-found-status.title')}
                description=""
                isShowAction={false}
            />
        );
    }

    if (hasVerificationProblem) {
        return (
            <TableMessage
                testSelector="verification-problem"
                iconType={TableMessageIconsEnum.warning}
                title={t('orders-table.messages.verification-problem.title')}
                description={t('orders-table.messages.verification-problem.description')}
                isShowAction
                actionTitle={t('orders-table.messages.verification-problem.action')}
                actionTheme={ButtonThemeEnum.secondary}
                onActionClick={goToProfile}
            />
        );
    }

    return (
        <TableMessage
            testSelector="empty"
            iconType={TableMessageIconsEnum.empty}
            title={t('orders-table.messages.empty.title')}
            description={t('orders-table.messages.empty.description')}
            isShowAction
            actionTitle={t('orders-table.messages.empty.action')}
            onActionClick={goToCreateOrderForm}
        />
    );
};

export default EmptyMessage;
