import { RFQSourceEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    dictTrailerId = 'dictTrailerId',

    address = 'address',

    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',

    createDates = 'createDates',
    createdById = 'createdById',
    createdByName = 'createdByName',

    source = 'source',
}

export type FormValuesT = {
    [FieldsEnum.dictTrailerId]: TrailerDictIdT | null;

    [FieldsEnum.address]: string | null;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;

    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.createdById]: DriverIdT | null;
    [FieldsEnum.createdByName]: string | null;

    [FieldsEnum.source]: RFQSourceEnum | null;
};
