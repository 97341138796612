import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './OrderDetails.scss';
import { OrderDetailsT } from 'common/store/order-details/models';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import OrderRouteLocations from 'common/components/order-details/OrderRouteLocations/OrderRouteLocations';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import ExcludedCountries from 'common/components/ExcludedCountries/ExcludedCountries';
import CaseIcon from 'common/icons/CaseIcon';
import TrailerIcon from 'common/icons/TrailerIcon';
import EmissionIcon from 'common/icons/EmissionIcon';
import TruckIcon from 'common/icons/TruckIcon';
import RouteIcon from 'common/icons/RouteIcon';
import { convertToKm } from 'common/utils/distance';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import DateFormatter from 'design-system/components/InfoTable/formatters/DateFormatter/DateFormatter';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import InvoiceBinaryStatusPill from 'common/components/status-pill/InvoiceBinaryStatusPill/InvoiceBinaryStatusPill';
import ShipperPriceOfferInfoTable, {
    ShipperPriceOfferInfoTablePropsT,
} from 'common/components/info-tables/ShipperPriceOfferInfoTable/ShipperPriceOfferInfoTable';
import Invoice2Icon from 'common/icons/Invoice2Icon';
import PaperIcon from 'common/icons/PaperIcon';
import LinkFormatter from 'design-system/components/InfoTable/formatters/LinkFormatter/LinkFormatter';
import ShipperContractStatusPill from 'common/components/status-pill/ShipperContractStatusPill/ShipperContractStatusPill';
import { CountryCodeT } from 'common/utils/api/models';
import ShipperContractLaneOrderCounterPill from 'common/components/pills/ShipperContractLaneOrderCounterPill/ShipperContractLaneOrderCounterPill';
import ShipperContractLaneStatusPill from 'common/components/status-pill/ShipperContractLaneStatusPill/ShipperContractLaneStatusPill';
import ShipmentDetailsCard from 'shipper/layouts/OrdersPage/OrderDetailsPage/OrderDetails/ShipmentDetailsCard/ShipmentDetailsCard';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import AsyncCountryFormatter from 'design-system/components/InfoTable/formatters/AsyncCountryFormatter/AsyncCountryFormatter';
import { urlFactory } from 'common/utils/urls';
import keyBy from 'lodash/keyBy';
import { useTrailersDict } from 'common/utils/hooks/useTrailersDict';
import { useTruckDict } from 'common/utils/hooks/useTruckDict';
import isNil from 'lodash/isNil';
import EmissionClassFormatter from 'design-system/components/InfoTable/formatters/EmissionClassFormatter/EmissionClassFormatter';

type PropsT = {
    order: OrderDetailsT | null | undefined;
    goToUserDetails: (userId: UserIdT | null) => void;
};

const cx = classNames.bind(styles);
const OrderDetails: React.FC<PropsT> = React.memo((props) => {
    const { order, goToUserDetails } = props;

    const { t } = useTranslation();

    const { truckDictById } = useTruckDict();
    const { trailersDictById } = useTrailersDict();

    const waypointById = useMemo(() => {
        return keyBy(order?.waypoints, 'id');
    }, [order]);

    const priceOffer = order?.priceOffer || null;

    const details: Array<InfoTableRowT> = [
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.trailer-type'),
            value: (
                <SimpleTrailerTypeFormatter
                    trailerDictType={trailersDictById[String(order?.trailerInfo?.id)] || null}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'trailer',
        },
        {
            icon: <EmissionIcon strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.emissions-class'),
            value: <EmissionClassFormatter emissionClass={order?.emissionClass} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'emission-class',
        },
    ];

    const assets: Array<InfoTableRowT> = [
        {
            icon: (
                <TruckIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.truck'),
            value: order?.truck ? (
                <AssetLabelFormatter
                    model={truckDictById[order?.truck?.dictTruckId]?.model}
                    plateNumber={order?.truck?.plateNumber}
                />
            ) : null,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
            testSelector: 'truck',
        },
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.trailer'),
            value: order?.trailer ? (
                <AssetLabelFormatter
                    model={trailersDictById?.[order?.trailer?.dictTrailerId]?.model}
                    plateNumber={order?.trailer?.plateNumber}
                />
            ) : null,
            emptyValue: t('common:info-table.placeholders.not-assigned'),
            isBoldValue: true,
            testSelector: 'trailer',
        },
    ];

    const creationDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CaseIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.order'),
            value: order?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'order-number',
        },
        {
            icon: null,
            name: t('common:order-details.columns.created-on'),
            value: <DateFormatter date={order?.createdOn} format="DD MMM YYYY" />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'create-date',
        },
        {
            icon: null,
            name: t('common:order-details.columns.author'),
            value: (
                <UserLinkFormatter
                    fullName={order?.createdBy?.fullName}
                    userId={order?.createdBy?.id}
                    byBroker={order?.createdByBroker}
                    onOpenUserDetails={() => {
                        goToUserDetails(order?.createdBy?.id || null);
                    }}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'author',
        },
        order?.shipperContract
            ? {
                  icon: (
                      <PaperIcon
                          size={DEFAULT_ICON_SIZE}
                          strokeColor={StyleGuideColorsEnum.gray}
                          fillColor={StyleGuideColorsEnum.light}
                      />
                  ),
                  name: t('common:order-details.columns.shipper-contract'),
                  value: (
                      <LinkFormatter
                          to={urlFactory.shipperContractDetails({
                              shipperContractId: order?.shipperContract?.id || '-',
                          })}
                      >
                          {order?.shipperContract?.name}
                      </LinkFormatter>
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  rightNode: order?.shipperContract ? (
                      <ShipperContractStatusPill isSymmetrical status={order?.shipperContract?.status} />
                  ) : null,
                  isBoldValue: true,
                  testSelector: 'shipper-contract',
              }
            : null,
        order?.shipperContractLane
            ? {
                  icon: (
                      <PaperIcon
                          size={DEFAULT_ICON_SIZE}
                          strokeColor={StyleGuideColorsEnum.gray}
                          fillColor={StyleGuideColorsEnum.light}
                      />
                  ),
                  name: t('common:order-details.columns.shipper-contract-lane'),
                  value: (
                      <LinkFormatter
                          to={urlFactory.shipperContractLaneDetails({
                              shipperContractId: order?.shipperContract?.id || '-',
                              shipperContractLaneId: order?.shipperContractLane?.id || '-',
                          })}
                      >
                          {order?.shipperContractLane?.tztLaneId}
                      </LinkFormatter>
                  ),
                  rightNode: (
                      <>
                          <ShipperContractLaneOrderCounterPill
                              ordersLeft={order?.shipperContractLane?.ordersLeft}
                              maxNumberOfOrders={order?.shipperContractLane?.maxNumberOfOrders}
                              status={order?.shipperContract?.status}
                              isSymmetrical
                          />
                          <ShipperContractLaneStatusPill isSymmetrical status={order?.shipperContractLane?.status} />
                      </>
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'shipper-contract-lane',
              }
            : null,
    ];

    const relativeEntities: Array<InfoTableRowT> = [
        {
            icon: (
                <Invoice2Icon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:order-details.columns.invoice'),
            value: order?.invoiceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            rightNode: <InvoiceBinaryStatusPill isPaid={!!order?.paid} isSymmetrical />,
            testSelector: 'invoice',
        },
    ];

    const countryCode: string | null = 'DE';

    const mileageDetails: Array<InfoTableRowT | null> = [
        {
            icon: <RouteIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:order-details.columns.payload-mileage'),
            value: (
                <UnitTypeCount type={UnitTypeEnum.kilometersAbbreviation} count={convertToKm(priceOffer?.distance)} />
            ),
            emptyValue: t('common:info-table.placeholders.empty'),
            isBoldValue: true,
            testSelector: 'total-mileage',
        },
        !'TODO'
            ? {
                  icon: <FlagIcon countryCode={countryCode} />,
                  name:
                      !countryCode || countryCode === 'null' ? (
                          t('common:rfq-details.unspecified')
                      ) : (
                          <AsyncCountryFormatter countryCode={countryCode as CountryCodeT} />
                      ),
                  value: (
                      <UnitTypeCount
                          type={UnitTypeEnum.kilometersAbbreviation}
                          count={convertToKm(priceOffer?.distance)}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.empty'),
                  isBoldValue: true,
                  testSelector: 'payload-mileage',
              }
            : null,
    ];

    const additionalServices = useMemo((): ShipperPriceOfferInfoTablePropsT['additionalServices'] => {
        if (!order?.additionalServices) {
            return [];
        }

        return order.additionalServices.reduce<NonNullable<ShipperPriceOfferInfoTablePropsT['additionalServices']>>(
            (acc, additionalService) => {
                if (additionalService.type && !isNil(additionalService.id) && !isNil(additionalService.cost)) {
                    acc.push({
                        enum: additionalService.type,
                        cost: additionalService.cost,
                        id: additionalService.id,
                    });
                }

                return acc;
            },
            [],
        );
    }, [order?.additionalServices]);

    const firstWaypoint = order?.waypoints?.[0] || null;
    const lastWaypoint = order?.waypoints?.[(order?.waypoints?.length || 0) - 1] || null;

    return (
        <>
            <OrderRouteLocations
                className={cx('route')}
                origin={firstWaypoint?.address}
                pickupDockingHoursFrom={firstWaypoint?.dateTimeFrom}
                pickupDockingHoursTo={firstWaypoint?.dateTimeTo}
                destination={lastWaypoint?.address}
                dropOffDockingHoursFrom={lastWaypoint?.dateTimeFrom}
                dropOffDockingHoursTo={lastWaypoint?.dateTimeTo}
            />
            <ExcludedCountries
                className={cx('excluded-countries')}
                titleNode={t('common:order-details.excluded-countries.title')}
                countryCodes={order?.prohibitedCountries || []}
                tooltipNode={t('order-details.excluded-countries.tooltip')}
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={creationDetails}
                testSelector="creation-details"
                isCollapsable
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                rows={relativeEntities}
                testSelector="relative-entities"
            />
            <InfoTable
                shouldRenderIcons
                className={cx('table')}
                isCollapsable
                rows={mileageDetails}
                testSelector="mileage-details"
            />
            <InfoTable shouldRenderIcons className={cx('table')} rows={details} testSelector="details" />
            <InfoTable shouldRenderIcons className={cx('table')} rows={assets} testSelector="assets" />
            <ShipperPriceOfferInfoTable
                className={cx('table')}
                hasContract={!!order?.shipperContract}
                totalPriceLabel={t('common:order-details.columns.price')}
                totalPrice={order?.priceOffer?.totalPrice}
                lineHaulCost={order?.priceOffer?.lineHaul}
                tollCost={order?.priceOffer?.tollCost}
                tollByRoadType={order?.priceOffer?.tollByRoadType}
                tranziitServiceFee={order?.priceOffer?.serviceFee}
                urgentOverprice={order?.priceOffer?.urgentOverprice}
                layoverCost={order?.priceOffer?.layoverCost}
                fuelCost={order?.priceOffer?.fuelCost}
                greenCost={order?.priceOffer?.greenOverprice}
                additionalServicesCost={order?.priceOffer?.additionalServicesCost}
                additionalServices={additionalServices}
                shouldAlwaysRenderExpandTrigger
            />
            {order?.shipments?.map((shipment, shipmentIndex) => {
                return (
                    <ShipmentDetailsCard
                        key={shipmentIndex}
                        className={cx('shipment')}
                        shipment={shipment}
                        isReeferTrailer={!!order?.trailerInfo?.reefer}
                        pickUpWaypoint={waypointById[shipment?.pickupPointId]}
                        dropOffWaypoint={waypointById[shipment?.dropOffPointId]}
                        shipmentNumber={shipmentIndex + 1}
                    />
                );
            })}
        </>
    );
});

export default OrderDetails;
