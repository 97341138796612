import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import { StyleGuideColorsEnum } from 'common/constants';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import noop from 'lodash/noop';

type PropsT = {
    onClose: () => void;
};

const TEST_SELECTOR = 'expiration-reserve-modal';

const ExpirationReserveModal: React.FC<PropsT> = React.memo((props) => {
    const { onClose } = props;

    const { t } = useTranslation();

    return (
        <Modal>
            <ModalContent onClose={noop}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.ok'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onClose();
                            },
                        },
                    ]}
                    icon={<BigWarningIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('common:error-modal.order-creation-expired-price-offer.title')}
                    message={t('common:error-modal.order-creation-expired-price-offer.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default ExpirationReserveModal;
